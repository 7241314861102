import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';  

import { Container, Box, Typography, CircularProgress, TextField, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles'; // Import useTheme hook to access theme variables

import userService from "../api/services/userService";
//import { use } from 'bcrypt/promises';

const OtpVerificationPage = () => {
	const isMobile = useSelector(state => state.global.isMobile);

	const navigate = useNavigate();
	const [token, setToken] = useSearchParams();
	const [message, setMessage] = useState('An verification email has been sent to your email. Pleace check your inbox (and spam folder).');
	let emailToken = token.get('token')

	/* Big M8 logo */
	const BigM8Logo = (
		<Box
			sx={{
				//backgroundColor: '#000', 
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				marginTop: 3,
			}}
			>
				<div className="logo-container">
					<img src='/logo/BM8.svg' alt="Logo" style={{ width: "75px" }} />
				</div>
		</Box>
	)

	const handleSubmit = async (event) => {
		try {
			const response = await userService.verifyToken(emailToken); console.log(response.status)
			if (response.status === 200) {
				localStorage.setItem("first-time",true); // Set a first-time flag to begin tutorial for new user
				localStorage.setItem("account-verified",true); // For displaying a account vertified message on login page
				navigate('/login');
			}   
		} catch (error) {
			if (error.response.status === 400 || error.response.status === 404) {
				setMessage(`Verification token expired. Please request another verification link by login with your email and password`);
			}
			console.error('Error verifying OTP:', error);
		}
	};
	useEffect(() => {
		if (token.size > 0) {
			handleSubmit();
		}
	},[])

	/* Back to login button */
	const BackToLogin = (
		<Link to="/login" >
			<Button
				type="button"
				fullWidthc
				variant="text"
				sx={
					isMobile ?
					{ mt: 2, color: '#333', textTransform: 'none' }
					:
					{ mt: -1, color: '#333', textTransform: 'none' }
				}
			>
				Back to login
			</Button>
		</Link>
	)

	return (
		<Container maxWidth="xs" sx={{textAlign:'center'}}>
			<p>{message}</p>

			{BackToLogin}
		</Container>
	);
};

export default OtpVerificationPage;