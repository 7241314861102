import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Formik, Form, Field } from 'formik';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, TextField, Button } from '@mui/material';
import { Autocomplete, Chip } from "@mui/material";
import EditSectionHeader from '../EditSectionHeader';
import { PhysicalForm } from '../../forms/createFormComponent';

import userService from 'api/services/userService';
import { updateUser } from 'app/slices/user.slice';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    black: '#121212',
  },
});

function EditPhysical({ setEdit }) {
  const isMobile = useSelector(state => state.global.isMobile);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const initialValues = {
    height: user.physical.height || '',
    weight: user.physical.weight || '',
    ethnicity: user.physical.ethnicity || '',
    eyeColor: user.physical.eyeColor || '',
    hairColor: user.physical.hairColor || '',
    bodyType: user.physical.bodyType || '',
    disability: user.physical.disability || [],
  };

  const [formValues, setFormValues] = useState(initialValues);

  const handleSave = async (values) => {
    const physical = values
    try {
      const response = await userService.modifyUserInfo({ physical });

      if (response) {
        dispatch(updateUser({ ...user, physical }));
      }

      setEdit(null);
    } catch (error) {
      console.error('Error updating physical info:', error);
    }
  };

  const handleSubmit = (values) => {
    setFormValues(values);
  };

  return (
    <Box className={`profile-edit${isMobile ? '-mobile' : ""}`}>
      <PhysicalForm 
        initialValues={formValues} 
        handleSave={handleSave} 
        onSubmit={handleSubmit}
        isEditMode={true} 
        setEdit={setEdit}
        margin={"normal"}
      />
    </Box>
  );
}

export default EditPhysical;
