import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    // General

    // Account

    // Notification
    emailNotifications: true, // Default to true
}

const settingSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        updateSettings: (state, action) => {
            const { emailNotifications } = action.payload;

            if (emailNotifications !== undefined) {
                state.emailNotifications = emailNotifications;
            }
        },
    },
});

export const { updateSettings } = settingSlice.actions;
export default settingSlice.reducer;