import React from 'react';
import PsuTable from 'components/admin/events/PsuTable';

export default function PennState() {
    return (
        <div className="PennState">
            <div id="google-sheets-data">
                <PsuTable />
            </div>
        </div>
    );
}