// React essentials
import React, { useState, useRef, useEffect, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  setGroupChatSessionId,
  updateGroupChatDetail,
  updateGroupChatMembers,
  updateGroupChatDetail as setGroupChatDetail, // Adjust if necessary
} from 'app/slices/groupchat.slice';

// MUI components
import {
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  Button,
  TextField,
} from '@mui/material';

// MUI icons
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';

// MUI style wrapper
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Custom components
import GroupChatHeader from './GroupChatHeader';
import GroupChatMessage from '../groupChatMessage/GroupChatMessage';
import GroupChatInputBox from './GroupChatInputBox';
import AcceptGroupInvitePrompt from './prompt/AcceptGroupInvitePrompt';

// Backend service calls
import groupChatService from 'api/services/groupchatService';
import chatService from 'api/services/chatService'; // If needed
import socket from 'socketio/socket';

// Custom scripts
import { delay } from 'scripts/delay';

// Custom styles
// Import custom styles if necessary

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

function preventScroll() {
  document.body.style.overflow = 'hidden';
}

function restoreScroll() {
  document.body.style.overflow = '';
}

function GroupChatWindow({ setProfileDrawerVisible }) {
  const isMobile = useSelector((state) => state.global.isMobile);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFocus = useSelector((state) => state.global.appOnFocus);

  // Visibility control states for mobile layout
  const [chatVisible, setChatVisible] = useState(true);

  // Group Chat data from Redux store
  const userId = useSelector((state) => state.user.userId);
  const groupChatId = useSelector((state) => state.groupchats.groupChatSessionId);
  const groupChat = useSelector((state) => state.groupchats.groupChatDetail);
  const groupChatUserAssociation = useSelector((state) => state.groupchats.groupSessionAssociation);
  const groupChatMembers = useSelector((state) => state.groupchats.groupChatMembers);

  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newMessage, setNewMessage] = useState('');

  const messageListRef = useRef(null);
  const inputRef = useRef(null);

  // Chat window layout
  const mobileLayout = {
    width: '100%',
    height: '93vh',
    display: groupChatId !== null && chatVisible ? 'block' : 'none',
  };
  const desktopLayout = {
    mt: 2,
    border: '2px solid var(--color-accent)',
    borderRadius: 'var(--border-radius-main)',
    width: '780px',
    height: '85vh',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-height: 650px)': {
      height: '80vh',
    },
    '@media (max-height: 508px)': {
      height: '75vh',
    },
    overflow: 'hidden',
  };

  // Update viewport height on resize
  useEffect(() => {
    const handleResize = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initialize on mount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Initialize group chats and user profiles based on groupChatId
  useEffect(() => {
    if (!groupChatId) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    Promise.all([
      groupChatService.getGroupChatDetail({ groupChatId }),
      groupChatService.getGroupChatMembers({ groupChatId }),
      groupChatService.getGroupChatMessages(groupChatId),
    ])
      .then(([groupChatDetailRes, groupChatMemberRes, groupChatMessageRes]) => {
        dispatch(updateGroupChatDetail(groupChatDetailRes.data));
        dispatch(updateGroupChatMembers(groupChatMemberRes.data));
        setChatMessages(groupChatMessageRes.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, [groupChatId, dispatch]);

  // Scroll to bottom when messages update
  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [chatMessages]);

  // Real-time chat updates via sockets
  useEffect(() => {
    const messageListener = (newChatMessage) => {
      if (newChatMessage.groupChatId === groupChatId) {
        setChatMessages((prevMessages) => [...prevMessages, newChatMessage]);

        // Scroll to bottom
        if (messageListRef.current) {
          messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
      }
    };

    socket.on('receiveGroupMessage', messageListener);
    socket.on('groupMessageSent', messageListener);

    return () => {
      socket.off('receiveGroupMessage', messageListener);
      socket.off('groupMessageSent', messageListener);
    };
  }, [groupChatId]);

  // Periodic message fetching every 5 seconds
  useEffect(() => {
    if (!groupChatId) return;

    const intervalId = setInterval(() => {
      groupChatService
        .getGroupChatMessages(groupChatId)
        .then((messagesRes) => {
          setChatMessages(messagesRes.data);
        })
        .catch((err) => {
          console.error('Error fetching group chat messages:', err);
        });
    }, 3000); // 5 seconds

    return () => clearInterval(intervalId);
  }, [groupChatId]);

  // Handle sending a new message
  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      const newGroupChatMessage = {
        groupChatId,
        senderId: userId,
        content: newMessage,
      };

      // Optimistically update messages
      setChatMessages((prevMessages) => [...prevMessages, { ...newGroupChatMessage, pending: true }]);

      // Emit the message to the server
      socket.emit('sendGroupMessage', newGroupChatMessage, (response) => {
        if (response.status === 'ok') {
          setChatMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg === newGroupChatMessage ? { ...msg, pending: false } : msg
            )
          );
        } else {
          setChatMessages((prevMessages) =>
            prevMessages.filter((msg) => msg !== newGroupChatMessage)
          );
          alert('Failed to send message. Please try again.');
        }
      });

      // Clear the input field and maintain focus
      setNewMessage('');
      if (inputRef.current) inputRef.current.focus();
    }
  };

  // Handle profile drawer (if needed)
  const handleProfileDrawer = () => {
    setProfileDrawerVisible(true);
  };

  // Handle deleting the group chat
  const handleDeleteGroupChat = () => {
    // Implement deletion logic similar to handleDeleteChat in ChatWindow
    // Example:
    // dispatch(setGroupChatSessionId(null));
    // groupChatService.removeGroupChat({ groupChatId })
    //   .then((res) => {
    //     // Handle successful deletion
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
  };

  // Accepting and applying groupChatId from URL parameters if any
  const urlParams = useParams();
  useEffect(() => {
    if (urlParams.groupChatId !== undefined) {
      dispatch(setGroupChatSessionId(urlParams.groupChatId));
    }
  }, [urlParams.groupChatId, dispatch]);

  /* Empty group chat */
  const emptyGroupChatId = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const NoChat = (
    <Box
      className="chat-main-empty"
      sx={
        isMobile
          ? { width: '100%', height: '100%' }
          : {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              width: '100%',
              height: '100%',
              fontSize: '100px',
              opacity: 1,
            }
      }
    >
      {!isMobile && <GroupsRoundedIcon fontSize="inherit" />}
    </Box>
  );

  /* Chat messages window */
  const ChatMessageWindow = (mesgRef, mesgs, membersInfo, uID) => {
    return (
      <Box
        className="chat-main-messages"
        sx={
          isMobile
            ? {
                mx: 1,
                flex: 1,
                maxHeight: `calc(var(--vh, 1vh) * 100)`,
                overflowY: 'auto',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  width: '0.5em',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'transparent',
                },
              }
            : {
                mx: 1,
                flex: 1,
                overflowY: 'auto',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  width: '0.5em',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'transparent',
                },
                borderBottomRightRadius: 'var(--border-radius-main)',
                borderBottomLeftRadius: 'var(--border-radius-main)',
              }
        }
        ref={mesgRef}
      >
        <>
          {mesgs.map((message, index) => (
            <GroupChatMessage
              key={index}
              userId={uID}
              senderInfo={membersInfo[message.senderId]}
              message={message}
              currentGroupChatId={groupChatId}
            />
          ))}
          <Box mb={2}></Box> {/* Spacer */}
        </>
      </Box>
    );
  };

  /* Chat input field */
  const ChatMessageInput = (
    <Box
      className="chat-main-input"
      sx={
        isMobile
          ? {
              position: 'fixed',
              bottom: 5,
              width: '100%',
              background: 'var(--color-main)',
              zIndex: 2,
              display: 'flex',
              alignItems: 'center',
              padding: 1,
              gap: 1,
            }
          : {
              position: 'sticky',
              bottom: 0,
              background: 'var(--color-main)',
              zIndex: 2,
              display: 'flex',
              alignItems: 'center',
              padding: 1,
              gap: 1,
            }
      }
    >
      <GroupChatInputBox
        newMessage={newMessage}
        setNewMessage={setNewMessage}
        handleSendMessage={handleSendMessage}
        inputRef={inputRef}
      />
    </Box>
  );

  /* Loading spinner */
  const LoadingSpinner = (
    <Box
      className="chat-main-empty"
      sx={
        isMobile
          ? { width: '100%', height: '100%' }
          : {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              width: '100%',
              height: '100%',
              fontSize: '100px',
              opacity: 1,
            }
      }
    >
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    </Box>
  );

  if (isLoading) {
    return (
      <Box className="chat-main" sx={isMobile ? mobileLayout : desktopLayout}>
        {LoadingSpinner}
      </Box>
    );
  }

  /* Empty group chat page */
  if (emptyGroupChatId(groupChat)) {
    return (
      <Box className="chat-main" sx={isMobile ? mobileLayout : desktopLayout}>
        {NoChat}
      </Box>
    );
  }

  return (
    <Box className="chat-main" sx={isMobile ? mobileLayout : desktopLayout}>
      <ThemeProvider theme={lightTheme}>
        <Box
          sx={{
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* Chat header */}
          {groupChat && <GroupChatHeader groupChat={groupChat} groupChatMembers={groupChatMembers} />}

          {/* Main chat window */}
          {groupChat && groupChatUserAssociation && (
            <>
              {/* Accept Group invite prompt */}
              {groupChatUserAssociation.acceptedInvite === false && (
                <AcceptGroupInvitePrompt groupChat={groupChat} />
              )}

              {groupChatUserAssociation.acceptedInvite === true && (
                <>
                  {ChatMessageWindow(messageListRef, chatMessages, groupChatMembers, userId)}
                  {ChatMessageInput}
                </>
              )}
            </>
          )}
        </Box>
      </ThemeProvider>
    </Box>
  );
}

export default GroupChatWindow;
