// frontend/src/components/forms/CustomEmojiPicker.js

import React, { useState } from 'react';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { Typography, IconButton, Box, Chip } from '@mui/material';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';


/**
 * CustomEmojiPicker Component
 * 
 * @param {Object} props
 * @param {Object} props.fieldConfig - Configuration for the form field
 * @param {Object} props.values - Form values containing the emoji array
 * @param {Function} props.setFieldValue - Function to update form values
 */
const CustomEmojiPicker = ({ fieldConfig, values, setFieldValue }) => {
  // Safely extract emoji values, defaulting to an empty array if undefined
  const emojiValues = values && values[fieldConfig.name] ? values[fieldConfig.name] : [];
  const [showPicker, setShowPicker] = useState(false);

  /**
   * Adds a new emoji to the array if the limit isn't exceeded
   * @param {Object} emoji - The selected emoji object
   */
  const addEmoji = (emoji) => {
    const { id, native } = emoji;
    const emojiObj = { id, native };
    const newEmojis = [...emojiValues, emojiObj];

    if (newEmojis.length <= 5) {
      setFieldValue(fieldConfig.name, newEmojis);
    } else {
      // Optionally, notify the user that the maximum number of emojis is reached
      alert('You can only select up to 5 emojis.');
    }

    setShowPicker(false);
  };

  /**
   * Removes an emoji from the array based on its index
   * @param {number} index - The index of the emoji to remove
   */
  const removeEmoji = (index) => {
    const newEmojis = [...emojiValues];
    newEmojis.splice(index, 1);
    setFieldValue(fieldConfig.name, newEmojis);
  };

  return (
    <Box sx={{ marginTop: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Box sx={{ width: "100%" }}>
        <Typography ml={1} variant="caption">{fieldConfig.label}</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', width: "100%", gap: 1 }}>
          {emojiValues.map((emoji, index) => (
            <Box key={index} position="relative" display="inline-block">
              <Chip
                label={emoji.native}
                onDelete={() => removeEmoji(index)}
                deleteIcon={<HighlightOffIcon />}
                variant="outlined"
                sx={{ fontSize: '1.2rem' }}
              />
            </Box>
          ))}
          {emojiValues.length < 5 && (
            <IconButton onClick={() => setShowPicker(true)} size='large'>
              <InsertEmoticonIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      {showPicker && (
        <Box sx={{ position: 'absolute', zIndex: 1000 }}>
          <Picker
            data={data}
            onEmojiSelect={addEmoji}
            theme="auto" // You can change the theme
            previewConfig={{
              showPreview: false
            }}
            searchConfig={{
              searchPlaceholder: 'Search Emojis'
            }}
          />
          <IconButton onClick={() => setShowPicker(false)} size='small' sx={{ position: 'absolute', top: 0, right: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

CustomEmojiPicker.propTypes = {
  fieldConfig: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default CustomEmojiPicker;
