import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import EventCard from './EventCard';
import Grid from '@mui/material/Unstable_Grid2';
import { Container, Typography, Box } from '@mui/material';

export default function EventList({ events, setEvents, displayMode}) {
  const isMobile = useSelector(state => state.global.isMobile);

  const displayStyleList = {
     display: 'flex',
     flexDirection: 'column',
     justifyContent: 'flex-start',
     alignContent: 'flex-start',
     width: 'inherit',
     overflowX: 'hidden',
  }
  const displayStyleGrid = {
    display: 'flex',
    flexDirection: isMobile ? 'row' : 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    overflowX: isMobile ? 'unset' : 'scroll',
    height: '78vh'
  }

  return (
    <Box>
      <Box 
        sx={displayMode === "List" ? displayStyleList : displayStyleGrid}
      >
        {events.length > 0 && events.map((event, index) => (
          <Grid key={index} mx={displayMode === "List" ? "unset": 2} my={displayMode === "List" ? "unset": 2}>
            <EventCard
              events={events}
              setEvents={setEvents}
              event={event}
              smallIcon={displayMode === "List" ? true : false}
            />
          </Grid>
        ))}
        
        {!events.length && (
          <Box ml={1} my={2}>
            <Typography variant="body2">No events.</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
