// src/components/search/SearchWithGroupChats.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Tabs, Tab, ThemeProvider, createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import groupchatService from '../../api/services/groupchatService';
import userService from 'api/services/userService';
import SearchResults from './SearchResults';
import { useSelector } from "react-redux";
import {
  BasicsSearchForm,
  CareerSearchForm,
  PhysicalSearchForm,
  PersonalitySearchForm,
  TheTeaSearchForm,
} from '../forms/createFormComponent';
import { isMobile } from '../../scripts/mobileCheck';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: grey[900],
          },
        },
      },
    },
    // Add other component overrides if necessary
  },
});

const SearchWithGroupChats = ({ results, setResults }) => {

  const groupChatId = useSelector((state) => state.groupchats.groupChatSessionId);
  console.log("groupChatId", groupChatId);
  const [searchCriteria, setSearchCriteria] = useState({
    prefer: {},
    require: {},
    exclude: {},
  });

  const [mainTabValue, setMainTabValue] = useState(0);
  const mainCategories = ['Prefer', 'Require', 'Exclude'];

  const [tabValue, setTabValue] = useState(0);

  const formsConfig = [
    { title: 'Basics', component: BasicsSearchForm, dataKey: 'basics' },
    { title: 'Career', component: CareerSearchForm, dataKey: 'career' },
    { title: 'Physical', component: PhysicalSearchForm, dataKey: 'physical' },
    { title: 'Personality', component: PersonalitySearchForm, dataKey: 'personality' },
    { title: 'The Tea', component: TheTeaSearchForm, dataKey: 'theTea' },
  ];

  /**
   * Updates the search criteria state based on category and section.
   */
  const updateSearchCriteria = (category, section, data) => {
    setSearchCriteria((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [section]: data,
      },
    }));
  };

  /**
   * Fetch searchCriteria from the backend on component mount.
   */

  useEffect(() => {
    console.log("Using effect to get search criteria from the database");
    groupchatService
      .getSearchCriteriaFromGroupchatDatabase(groupChatId)
      .then((res) => {
        console.log("res from the backend", res);
        if (res) {
          console.log("setting search criteria from the database as it is not null", res.searchCriteria);
          setSearchCriteria(res.searchCriteria);
          console.log("finally after searchCriteria", searchCriteria);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /**
   * Handles form submission by updating the searchCriteria in the backend.
   */
  const handleSubmit = () => {
    userService
      .searchUserInfos(searchCriteria)
      .then((res) => {
        setResults(res);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("body generated before call", groupChatId, searchCriteria);
    groupchatService.addToGroupChatDataBase({ searchCriteria, groupChatId })
      .then((res) => { console.log(res); })
      .catch((err) => { console.log(err); });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
    setTabValue(0);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Box
        className="search"
        sx={
          isMobile()
            ? {
                width: '100%',
                border: '0px solid var(--color-accent)',
              }
            : {
                width: '450px',
                height: '100%',
                overflowY: 'scroll',
                border: '2px solid var(--color-accent)',
                borderRadius: 'var(--border-radius-main)',
              }
        }
      >
        <Box mx={2} my={2} mb={5}>
          <Tabs
            value={mainTabValue}
            onChange={handleMainTabChange}
            variant="fullWidth"
            textColor="primary"
            indicatorColor="primary"
            sx={{
              '.MuiTab-textColorPrimary': { color: grey[900] },
              '.MuiTabs-indicator': { backgroundColor: grey[900] },
            }}
          >
            {mainCategories.map((category, index) => (
              <Tab label={category} key={index} />
            ))}
          </Tabs>

          {mainCategories.map((category, mainIndex) => (
            <div
              role="tabpanel"
              hidden={mainTabValue !== mainIndex}
              id={`main-tabpanel-${mainIndex}`}
              aria-labelledby={`main-tab-${mainIndex}`}
              key={mainIndex}
            >
              {mainTabValue === mainIndex && (
                <Box>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{
                      '.MuiTab-textColorPrimary': { color: grey[900] },
                      '.MuiTabs-indicator': { backgroundColor: grey[900] },
                    }}
                  >
                    {formsConfig.map((form, index) => (
                      <Tab label={form.title} sx={{ textTransform: 'none' }} key={index} />
                    ))}
                  </Tabs>

                  {formsConfig.map((form, index) => (
                    <div
                      role="tabpanel"
                      hidden={tabValue !== index}
                      id={`tabpanel-${index}`}
                      aria-labelledby={`tab-${index}`}
                      key={index}
                    >
                      {tabValue === index && (
                        <Box p={3}>
                          <form.component
                            initialValues={
                              searchCriteria[mainCategories[mainTabValue].toLowerCase()] &&
                              searchCriteria[mainCategories[mainTabValue].toLowerCase()][form.dataKey]
                            }
                            onSubmit={(data) =>
                              updateSearchCriteria(
                                mainCategories[mainTabValue].toLowerCase(),
                                form.dataKey,
                                data
                              )
                            }
                            onValuesChange={(updatedValues) => {
                              updateSearchCriteria(
                                mainCategories[mainTabValue].toLowerCase(),
                                form.dataKey,
                                updatedValues
                              );
                            }}
                            isEditMode={false}
                            margin={'dense'}
                          />
                        </Box>
                      )}
                    </div>
                  ))}
                </Box>
              )}
            </div>
          ))}

          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                backgroundColor: 'var(--color-accent)',
                borderRadius: '9999px',
                textTransform: 'none',
                transition: '0.2s',
                color: 'var(--color-text-invert)',
                opacity: 1,
                '&:hover': {
                  backgroundColor: 'var(--color-accent)',
                  color: 'var(--color-text-invert)',
                  opacity: 0.7,
                },
              }}
            >
              Search
            </Button>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default SearchWithGroupChats;