import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { updateUser } from '../../../app/slices/user.slice';
import userService from '../../../api/services/userService';

import { Formik, Form } from 'formik';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import { Autocomplete, Chip, TextField } from "@mui/material";
import EditSectionHeader from '../EditSectionHeader';

// import CareerForm from '../../forms/CareerForm';
import { CareerForm } from '../../forms/createFormComponent';


const lightTheme = createTheme({
  palette: {
    mode: 'light',
    black: '#121212',
  },
});

function EditCareer({ setEdit }) {
  const isMobile = useSelector(state => state.global.isMobile);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const initialValues = {
    schools: user.career.schools || [],
    classes: user.career.classes || [],
    occupation: user.career.occupation || [],
    company: user.career.company || '',
    income: user.career.income || '',
  };

  const [formValues, setFormValues] = useState(initialValues);

  const handleSave = async (values) => {
    const career = values
    try {
      const response = await userService.modifyUserInfo({ career });
      if (response) {
        dispatch(updateUser({ ...user, career }));
      }
      setEdit(null);
    } catch (error) {
      console.error('Error updating career info:', error);
    }
  };

  const handleSubmit = (values) => {
    setFormValues(values);
  };

  return (
    <Box className={`profile-edit${isMobile ? '-mobile' : ""}`}>
      <CareerForm 
        initialValues={formValues} 
        handleSave={handleSave} 
        onSubmit={handleSubmit}
        isEditMode={true} 
        setEdit={setEdit}
        margin={"normal"}
      />
    </Box>
  );
}

export default EditCareer;
