// Chat page (/chat), also M8

// React, Redux, DOM essentials
import React, { useState, useEffect, useReducer } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// MUI components
import { 
  List,
  ListItem, 
  ListItemAvatar, 
  ListItemText, 
  Avatar, 
  Typography, 
  Box, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  TextField, 
  IconButton, 
  Button,
  Tabs,
  Tab 
} from '@mui/material';

// MUI icons
import AddIcon from '@mui/icons-material/Add';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';

// Custom theme 
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Backend service calls
import chatService from 'api/services/chatService';

// Custom components
import CategoryList from 'components/chats/categoryList/CategoryList';
import ChatList from 'components/chats/chatList/ChatList';
import GroupChatListView from 'components/groupchats/GroupChatListView';
import GroupChatWindow from 'components/groupchats/groupChatWindow/GroupChatWindow';
import MemberProfile from 'components/userProfile/MemberProfile';
import GroupChatProfile from 'components/groupchats/groupChatProfile/GroupChatMemberProfile';

import PopupSlideshow from 'components/popup/Slideshow/PopupSlideshow';
import M8sPageTutorial from 'components/tutorial/M8s/M8sPageTutorial';
import HelpButton from 'components/tutorial/HelpButton';

// Custom scripts
import { isMobile } from 'scripts/mobileCheck';
import { delay } from 'scripts/delay';


const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});


export default function GroupChatPage({ chats }) {
  const isMobile = useSelector(state => state.global.isMobile);
  
  // Visibility control states for mobile layout
  const [profileDrawerVisible, setProfileDrawerVisible] = useState(false); 

  // Chatting user (non current login user) ID and info states 
  const [chatUserInfo, setChatUserInfo] = useState({});

  // First-time User Experience: Show tutorial popup for first-time users 
  const [tutorialVisible, setTutorialVisible] = useState(false);
  const tutorialPopup = (
      <PopupSlideshow 
        content={<M8sPageTutorial tutorialVisible={tutorialVisible} setTutorialVisible={setTutorialVisible} />} 
        visible={tutorialVisible} 
        setVisible={setTutorialVisible}
        backdrop={true}
        association={"first-time-m8"}
      />
  );
  // Floating help button logic 
  const handleHelpButtonClick = () => {
    localStorage.setItem("first-time-m8", true);
    setTutorialVisible(true);
  }
  useEffect(() => {
    localStorage.getItem("first-time-m8") && setTutorialVisible(true);
  },[])

  /* Side menu */
  const [chatType, setChatType] = React.useState(0);
  const handleChatTypeChange = (event, newValue) => {
      setChatType(newValue);
  };
  const chatTypes = [
      {label: "Dual Chats"},
      {label: "Group Chats"},
  ]
  const ChatTypeMenu = (<>
      <Tabs
      orientation="horizontal"
      variant="scrollable"
      value={chatType}
      onChange={handleChatTypeChange}
      aria-label="Vertical tabs example"
      textColor="primary"
      indicatorColor="primary"
      sx={
          { 
              color: 'var(--color-text)',
              borderRight: 0, 
              borderColor: 'var(--color-accent)',
              '.MuiTab-textColorPrimary': { color: 'var(--color-accent)' },
              '.MuiTabs-indicator': { backgroundColor: 'var(--color-accent)' },
              maxWidth: 250,
              width: 250
          }
      }
    >
      {chatTypes.length > 0 && chatTypes.map((option, index) => 
              <Tab key={index} label={option.label} sx={{alignItems: 'start', textTransform: "none",}} />
      )}
    </Tabs>
  </>)

  /* List view */
  const listView = (
    <GroupChatListView
      group={true} 
    />
  )

  /* Chat window */
  const emptyChatId= (obj) => {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }
  
    return true;
  }
  
  const Chat = (
    <GroupChatWindow
      setProfileDrawerVisible={setProfileDrawerVisible} // For mobile, use drawer to show user profile
    /> 
  )

  /* Profile window */
  // Function to handle Drawer (mobile only)
  const toggleProfileDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setProfileDrawerVisible(open);
  };
  const profileView = (
   <GroupChatProfile profileDrawerVisible={profileDrawerVisible} toggleProfileDrawer={toggleProfileDrawer}/>
  )


  return (
    <ThemeProvider theme={lightTheme}>

      {/* First time user tutorial */}
      {tutorialPopup}
      
      {/* Main container */}
      <Box className="chat-page-container" sx={{display: 'flex', justifyContent: 'center', width: '100%', gap: 2}}>

        {/* Category/Chat list */}
        {listView}

        {/* Main chat window */}
        {Chat}

        {/* Profile window */}
        {profileView}

        {/* Help button */}
        {/*isMobile && (
          chatId === null && <HelpButton onClick={handleHelpButtonClick}/>
        )*/}
        {/*!isMobile && <HelpButton onClick={handleHelpButtonClick}/>*/}

      </Box>
    </ThemeProvider>
  );
}
