// React, Redux, DOM essentials
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// MUI components
import { 
    Box,
    Typography,
    Button 
} from "@mui/material";

// Socket.IO
import socket from "socketio/socket";

// Backend services
import chatService from 'api/services/chatService';
import groupChatService from 'api/services/groupchatService';
import userService from 'api/services/userService';

// Redux state management 
import {
    setGroupChatSessionId,
    updateGroupChatUserAssociations,
    updateGroupSessionAssociation,
    updateGroupChats
} from 'app/slices/groupchat.slice'


export default function AcceptGroupInvitePrompt({ groupChat }) {
    const isMobile = useSelector(state => state.global.isMobile);
    const dispatch = useDispatch();
    const onFocus = useSelector(state => state.global.appOnFocus);

    const userId = useSelector(state => state.user.userId);
    const groupChatId = useSelector(state => state.groupchats.groupChatSessionId); 

    let vh = window.innerHeight * 0.01;

    // Accept Group invite prompt handles
    const acceptInvite = async () => {
        const response = await groupChatService.acceptUserToGroupChat({ groupChatId: groupChatId, userId: userId })
        .then ((updatedGroupChatUserAssociations) => {
            dispatch(updateGroupChatUserAssociations(updatedGroupChatUserAssociations));
            dispatch(updateGroupSessionAssociation(updatedGroupChatUserAssociations[groupChatId]))
        })
        .catch((error) => {
            void(0);
        })
    }
    const rejectInvite = async () => {
        const response = await groupChatService.removeUserFromGroupChat({ groupChatId: groupChatId, userId: userId })
        .then((res) => {
        if (res.status === 200) {
            dispatch(setGroupChatSessionId(null));
            dispatch(updateGroupSessionAssociation(null));
            dispatch(updateGroupChats(res.data));


        }
        })
        .catch((error) => {
            void(0);
        })
    }

    /* Accept Group invite prompt */
    const AcceptGroupInvitePrompt = (
        <Box
        className="chat-main-messages"
        sx={ isMobile ?
            { // Mobile layout
            mx: 1,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            maxHeight: '100vh', // Fallback for old browsers
            maxHeight: `calc(var(--vh, 1${vh}) * 100)`, // See: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
            overflow: 'auto',
            scrollbarWidth: 'none', // Hide the scrollbar in Firefox
            '&::-webkit-scrollbar': {
                width: '0.5em',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'transparent', // Hide the scrollbar thumb
            }
            }
            :
            { // PC layout
            mx: 1,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            overflow: 'auto',
            scrollbarWidth: 'none', // Hide the scrollbar in Firefox
            '&::-webkit-scrollbar': {
                width: '0.5em',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'transparent', // Hide the scrollbar thumb
            },

            borderBottomRightRadius: 'var(--border-radius-main)',
            borderBottomLeftRadius: 'var(--border-radius-main)'
            }
        }
        
        >
        <Box sx={{textAlign: 'center'}}>
            <Typography>Accept invite to group chat: <b>{groupChat && groupChat.name}</b>?</Typography>
            <Button
            variant="contained"
            sx={{
                textTransform: 'none',
                width: 80,
                mt: 4,
                mr: 1,
                ml: 1,
                mb: 2,
                backgroundColor: 'var(--color-accent)', // Set the background color 
                color: 'var(--color-text-invert)', // Set the text color
                border: '2px solid var(--color-accent)', // Set a border
                '&:hover': {
                    backgroundColor: 'var(--color-main)', // Change background color on hover
                    color: 'var(--color-text)', // Change text color on hover
                }
            }}
            onClick={acceptInvite}
        >
            Accept
        </Button>
        <Button
            variant="contained"
            sx={{
                textTransform: 'none',
                width: 80,
                mt: 4,
                mr: 1,
                ml: 1,
                mb: 2,
                backgroundColor: 'var(--color-accent)', // Set the background color 
                color: 'var(--color-text-invert)', // Set the text color
                border: '2px solid var(--color-accent)', // Set a border
                '&:hover': {
                    backgroundColor: 'var(--color-main)', // Change background color on hover
                    color: 'var(--color-text)', // Change text color on hover
                }
            }}
            onClick={rejectInvite}
        >
            Reject
        </Button>
        </Box>
        
        </Box>
    )

    return AcceptGroupInvitePrompt;
}