// React essentials
import React, { useState } from "react";

// MUI components
import { 
	Fade,
	Modal,
	Box,
	Button,
	Backdrop,
	Typography,
} from "@mui/material";

// Popup content
import WhatIsM8Content from "components/help/WhatIsM8";

// Custom styles
import { popupStyle } from "../styles/popupStyles";

export default function WhatIsM8({openPopup, setOpenPopup}) {
	const WhatIsM8 = (<>
		{/* "What is M8?" button */}
		<Button
			type="button"
			variant="contained"
			sx={{
				textTransform: 'none',
				width: 350,
				mt: 1,
				backgroundColor: 'var(--color-main)', 
				color: 'var(--color-accent)', 
				border: '2px solid var(--color-accent)', 
				'&:hover': {
					backgroundColor: 'var(--color-accent)', 
					color: 'var(--color-main)', 
				}
			}}
			onClick={() => {
					//setOpenPopup4(true)
					setOpenPopup(true); // Open the popup
				}
			}
		>
				What is M8?
		</Button>

		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={openPopup}
			onClose={() => setOpenPopup(false)}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={openPopup}>
				<Box sx={popupStyle}>
					<Box sx={{ position: 'fixed', top: 10, right: 10, zIndex: 1 }}>
							<Button
								onClick={() => setOpenPopup(false)}
								sx={{
									fontSize: '20px', 
									fontWeight: 'bold', 
									color: 'black', 
								}}
							>
									&#x2715;
							</Button>
					</Box>

					<WhatIsM8Content />

					{/* Close button */}
					<Button 
						onClick={() => setOpenPopup(false)}
						sx={{
							backgroundColor: 'var(--color-accent)', 
							opacity: 1,
							transition: '0.25s',
							'&:hover': {
								backgroundColor: 'var(--color-accent)', 
								opacity: 0.8,
							},
							borderRadius: '9999px',
							color: 'var(--color-main)', 
							width: 80
						}}
					>
							Close
					</Button>
				</Box>
			</Fade>
		</Modal>
	</>)

	return (WhatIsM8)
}