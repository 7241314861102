// socket.js
import { io } from 'socket.io-client';

// Configuration object for Socket.IO
const connectionObject = {
  autoConnect: true,
  withCredentials: true,
  // Add any additional connection options if needed
};

// Initialize the Socket.IO client
const socket = io(process.env.REACT_APP_ORIGIN_URL, connectionObject);

// Optional: Handle connection events
socket.on('connect', () => {
  console.log('Socket connected:', socket.id);
});

socket.on('disconnect', () => {
  console.log('Socket disconnected');
});

// Export the socket instance for use in other components
export default socket;
