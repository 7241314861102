import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import settingService from 'api/services/settingService';

import { updateSettings } from 'app/slices/setting.slice';

import { Container, Tab, Tabs, Typography, Box, Button, Divider, RadioGroup, Radio, FormControl, FormControlLabel } from '@mui/material';
import SaveSettingsButton from './saveButton/SaveSettingsButton';

import { delay } from 'scripts/delay';

export default function AccountSettings() {
    const isMobile = useSelector(state => state.global.isMobile);
    const dispatch = useDispatch();

    /* Password Change */
    // Main element
    const ChangePassword = (<>
        <Typography variant='h6' fontWeight={'bold'} mt={3}>Change Password </Typography>
        <Typography fontWeight={'normal'} sx={{opacity: 0.5}}>Click here to </Typography>
        
    </>)

    /* Function for Save Button */
    const handleSave = () => {
        //settingService.updateUserSetting({ }).then(async (res) => {
        //    dispatch(updateSettings(res));
        //})
        
    }

    return (<>
        {/*ChangePassword*/}
        
        {/* Save Button */}
        <SaveSettingsButton handleSave={handleSave}/>
    </>)
}