import React, { useEffect, useState, useReducer} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 

import socket from 'socketio/socket';

import { 
  List,
  ListItem, 
  ListItemAvatar, 
  Avatar, 
  ListItemText, 
  Typography, 
  Box, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  TextField, 
  IconButton, 
  Button,
   
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { textFieldOverrides } from 'styles/MUIOverriders/textFieldStyleOverrider';
import { outlinedInputOverrides } from 'styles/MUIOverriders/outlinedInputStyleOverrides';
import { dialogButtonRegular } from "styles/dialogButtonStyles";
import { dialogButtonFocus } from "styles/dialogButtonStyles";

import { 
  updateCategories,

  setSelectedCategoryId,
  setChatSessionId
} from 'app/slices/chat.slice';

import GroupChatListItem from './GroupChatListItem';

import chatService from 'api/services/chatService';
import groupChatService from 'api/services/groupchatService';

import { 
  addGroupChat, 
  updateGroupChats,
  updateGroupChatUserAssociations
} from 'app/slices/groupchat.slice';

import { delay } from 'scripts/delay';

const customTheme = createTheme({
  components: {
    ...textFieldOverrides,
    ...outlinedInputOverrides,
  },
});

const mobileStyle = {
  top: '-25%', // adjust this value as needed

};

export default function GroupChatList({ group, chatTypeMenuVisible }) {
  const isMobile = useSelector(state => state.global.isMobile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, forceUpdate] = useReducer(x => x + 1, 0);
 
  const userId = useSelector(state => state.user.userId);
  const groupChats = useSelector(state => state.groupchats.groupChats);
  const chatUserAssociation = useSelector(state => state.chats.chatUserAssociation);

  // "Add group chat" Dialog handler
  const [isAddChatDiaOpen, setAddChatDiaOpen] = useState(false);
  const handleOpenAddChatDia = () => {
    setAddChatDiaOpen(true);
  };
  const handleCloseAddChatDia = () => {
    setAddChatDiaOpen(false);
  };

  // Add new group chat dialog
  const [newGroupName, setNewGroupName] = useState('');
  const handleAddGroupChat = async () => {
    if(newGroupName) {
      groupChatService.createGroupChat({userId, groupName: newGroupName})
      .then(res => {
        dispatch(addGroupChat(res.data));

        // Get latest Group Chat and User associations
        groupChatService.getGroupChatUserAssociations()
        .then(res => {
          dispatch(updateGroupChatUserAssociations(res.data));
        })
        .catch(error => {
          void(0);
        })

      })
      .catch(error => {
        void(0);
      })
      handleCloseAddChatDia();
    }
  };
  const NewCategoryDialogue = (
    <Dialog open={isAddChatDiaOpen} onClose={handleCloseAddChatDia} sx={mobileStyle}>
        <DialogTitle sx={{ fontSize: "1rem", mb: 2 }}>Create a new Group Chat</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="groupChatName"
            label="Group Chat Name"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
          />
        </DialogContent>
        
          {/* Cancel / Confirm addition of category (buttons) */}
          <DialogActions>
            <Button className="category-add-cancel" sx={dialogButtonRegular} onClick={handleCloseAddChatDia}>Cancel</Button>
            <Button className="category-add-confirm" sx={dialogButtonFocus} size="small" variant="contained" onClick={handleAddGroupChat}>Add</Button>
          </DialogActions>
    </Dialog>
  )
  
  /* Chat list */
  const ChatList = (
    <List 
      className='List'
      sx={isMobile ?
        {
          border: '2px solid var(--color-accent)',
          borderRadius: 'var(--border-radius-main)',
          mt: 2,
          mx: 1,
          overflowY: 'scroll',
          height: chatTypeMenuVisible ? '78vh' : '80vh',
          '@media (max-height: 870px)': {
            height: '75vh',
          },
          '@media (max-height: 700px)': {
            height: '70vh',
          },
        }
        :
        {
          width: '100%',
          height: '100%',
          overflowY: 'scroll',
        }
      }
    >
      {/* If there exist group chats */}
      {groupChats && Object.keys(groupChats).length  > 0 && Object.values(groupChats).map(groupChat => (
          <GroupChatListItem 
            key={groupChat._id}
            groupChat={groupChat}
          />
      ))}
      {/* If there exist no group chats */}
      {groupChats && Object.keys(groupChats).length === 0 && (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', textAlign: 'center', flexWrap: 'wrap', alignContent: 'center', justifyContent: 'center'}}>
          <Box>
            <Typography variant="body1" color="textSecondary" width='100%'>
              No group chats.
            </Typography>
          </Box>
        </Box>
      )}
    </List>
  );

  // Refresh chat list when chat operations happen
  useEffect(() => {
    
  },[groupChats]);

  // Group Chat title & New Group Chat button
  const GroupChatListTitle = (
    <Box
        className="category-list"
        sx={
          isMobile ?
          { // Mobile layout
            
          }
          :
          { // PC layout
            mt: 0,
            mb: 2,
          }
        }
      >
      <Box 
          className="category-title"
          sx={
            isMobile ?
            { // Mobile layout
              display: "flex", 
              width: "100%", 
              alignItems: "center", 
              justifyContent: "space-between" 
            }
            :
            { // PC layout
              display: "flex", 
              width: "100%", 
              alignItems: "center", 
              justifyContent: "space-between" ,
            }
          }
        >
        
          {/* Title */}
          <Typography variant="h5" component="div" sx={{ ml: 2, fontWeight: 'bold' }}>
            Group Chats
          </Typography>

          {/* Category addition button */}
          <IconButton size="small" onClick={handleOpenAddChatDia} sx={{ mr: 0 }}>
            <AddIcon />
          </IconButton>
      </Box>
    </Box>
  )


  return (
    <ThemeProvider theme={customTheme}>
    <Box className="category-container" sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
    <Box
      className="category"
      sx={
        isMobile ?
        {
          width: '100%',

          display: 'flex',
          justifyContent: "center",
          flexDirection: 'column'
        }
        :
        {
          width: '100%',
          borderRadius: 'var(--border-radius-main)',
          display: 'flex',
          justifyContent: "center",
          flexDirection: 'column',
          height: chatTypeMenuVisible ? 'calc(85vh - 56px)' : 'calc(85vh - 35px)',
            '@media (max-height: 650px)': {
              height: chatTypeMenuVisible ? 'calc(80vh - 56px)' : 'calc(80vh - 35px)'
            },
            '@media (max-height: 508px)': {
              height: chatTypeMenuVisible ? 'calc(75vh - 56px)' : 'calc(75vh - 35px)'
            },
        }
      }
    >

      {/* Title container & group chat add button*/}
      {GroupChatListTitle}

      {/* Group chat list */}
      {ChatList}

      {/* Group chat addition dialog */}
      {NewCategoryDialogue}

    </Box>
    </Box>
    </ThemeProvider>
  );
}