// React, Redux, DOM essentials
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { useDispatch, useSelector } from 'react-redux';

// MUI components
import { ListItem, ListItemAvatar, Avatar, ListItemText, Box, Typography, ButtonBase } from '@mui/material';

// MUI icons
import PersonIcon from '@mui/icons-material/Person';

// API service calls
import userService from 'api/services/userService';
import chatService from 'api/services/chatService';

// Socket.IO
import socket from 'socketio/socket';

// Redux state management
import {
  setChatSessionId, 
  setFriendId, 
  setFriendUserInfo 
} from 'app/slices/chat.slice';

// Custom funcstions
import { delay } from 'scripts/delay';



function ChatListItem({ chat, friendId }) {
  const dispatch = useDispatch();

  const userId = useSelector(state => state.user.userId);
  const currentChatId = useSelector(state => state.chats.chatSessionId);

  const lastMessage = useSelector(state => state.chats.lastMessages[chat._id])
  const [friend, setFriend ] = useState({});

  // Get detail about the chatting/friend user
  const getFriendDetail = async () => {
    const friendInfo = await userService.getUserDetails({ userId: friendId });
    setFriend(friendInfo);
  }
  useEffect(() => {
    getFriendDetail();
  },[friendId])

  // Get unread message count for individual chat in list.
  const [unreadCount, setUnReadCount] = React.useState(0);
  const user = useSelector(state => state.user);
  const getUnreadCount = async () => {
    try {
      const counter = await chatService.getUnreadMessageCount({ userId: user.userId, serviceCode: 2, chatId: chat._id });
      setUnReadCount(counter.data.unreadCounter);
    } catch (error) {
      throw error;
    }
  };

  // Polling to update unread count every 3 seconds
  useEffect(() => {
    // Initial fetch
    getUnreadCount();

    // Set interval to fetch unread count every 3 seconds
    const intervalId = setInterval(() => {
      getUnreadCount();
    }, 3000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [chat._id, user.userId]);

  // Chat list item style (changes for current selected chat)
  const [listItemBodyColor, setListItemBodyColor] = useState('');
  useEffect(() => {
    currentChatId === chat._id ? 
      setListItemBodyColor('var(--color-accent2)') 
    : 
      setListItemBodyColor('');
  },[currentChatId])

  /* Unread message notification */
  const UnreadMessageIndicator = (<>
  {unreadCount !== 0 ?
      <Box 
      sx={{
        backgroundColor : "var(--color-accent)", 
        color : "var(--color-main)", 
        borderRadius : "50%", 
        padding: "0px", 
        paddingLeft: "6px",
        paddingRight: "6px",
        marginLeft: "15px", 
        textAlign : "center",
        
      }}
    > 
      <Typography fontWeight={'bold'} fontSize={12}>{unreadCount}</Typography>
    </Box>
    :
    <Box 
      sx={{
        color : "var(--color-main)", 
        borderRadius : "50%", 
        padding: "0px", 
        paddingLeft: "6px",
        paddingRight: "6px",
        marginLeft: "80px", 
        textAlign : "center",
      }}
    > 
      <Typography fontWeight={'bold'} fontSize={12} sx={{color: listItemBodyColor}}>0</Typography>
    </Box>
  }
  </>)

  // Get unread message count Socket.io signal listeners
  useEffect(() => {
    getUnreadCount(); // Check for unread messages on app launch first

    const unreadCountFunctionCall = () => {
      //dispatch(incrementUnreadCount());
      getUnreadCount();
    }
    
    socket.on('newChat', unreadCountFunctionCall);
    socket.on("receiveMessage", unreadCountFunctionCall);
    socket.on('chatRead', unreadCountFunctionCall);

    return () => {
      socket.off('newChat', unreadCountFunctionCall);
      socket.off('receiveMessage', unreadCountFunctionCall);
      socket.off('chatRead', unreadCountFunctionCall);
    };
  }, [socket]);
  
  // Chat item click handler
  const itemClickHandle = async () => {

    // Set chat session
    dispatch(setChatSessionId(chat._id));

    // Set chat in session as Read immediately
    let delayer = await delay(1);
    getUnreadCount();
  }

  return (
    <ButtonBase 
      component="div"
      sx={{ 
        width: "90%", 
        backgroundColor: listItemBodyColor,
        borderRadius: 'var(--border-radius-main)',
        "&:hover": {
          cursor: 'pointer'
        } 
      }} 
    >
      <ListItem /*component={Link} to={`/chat/${chat._id}`}*/ 
        onClick={itemClickHandle}
      >
          <ListItemAvatar>
            <Avatar alt={friend.name && friend.name} src={Object.hasOwn(friend, 'images') && friend.images.length > 0 ? friend.images[0].mediaUrl : 'unknown'} >
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            sx={{
              textDecoration: "none", 
              color: "var(--color-accent)",
              '.MuiListItemText-secondary': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }
            }}
            primary={friend.name && friend.name} 
            secondary={lastMessage.content} 
          />
          
          {UnreadMessageIndicator}
      </ListItem>
    </ButtonBase>
    
  );
}

export default ChatListItem;