import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';
import debounce from 'lodash/debounce';

const CustomMultiAutocomplete = ({ fieldConfig, values, onSubmit, margin, image }) => {
  const [options, setOptions] = useState([]);
  const [localValue, setLocalValue] = useState(values[fieldConfig.name] || []);

  const handleDynamicSearch = debounce(async (searchTerm) => {
    const data = await fieldConfig.fetchOptions(searchTerm);
    setOptions(data || []);
  }, 300);

  useEffect(() => {
    if (fieldConfig.options) {
      setOptions(fieldConfig.options);
    }
    if (fieldConfig.fetchOptions) {
      handleDynamicSearch('');
    }
  }, [fieldConfig.options, fieldConfig.fetchOptions]);

  const formatLabel = (option) => {
    if (fieldConfig.name === 'bodyType') {
      const parts = option.split('/');
      const fileName = parts[parts.length - 1];
      return fileName.split('.')[0];
    } else {
      return option;
    }
  };

  return (
    <Autocomplete
      multiple
      options={options}
      margin={margin}
      name={fieldConfig.name}
      value={localValue} // Use local state for Autocomplete input
      onInputChange={(event, value) => {
        if (fieldConfig.fetchOptions) handleDynamicSearch(value);
      }}
      onChange={(event, newValue) => {
        setLocalValue(newValue); // Update local state
        onSubmit({ ...values, [fieldConfig.name]: newValue }); // Pass to parent only on change
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            label={formatLabel(option)}
            {...getTagProps({ index })}
            key={index}
          />
        ))
      }
      renderOption={(props, option) => (
        <li {...props}>
          {image ? <img src={option} alt={option} height="150" style={{ marginRight: 5 }} /> : option}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={fieldConfig.label} margin="normal" size="small" fullWidth />
      )}
      key={fieldConfig.name}
    />
  );
};

export default CustomMultiAutocomplete;
