// React essentials
import React, { useEffect, useState, useRef } from 'react';

// Redux essentials
import { useSelector } from 'react-redux';

// Socket.io
import socket from 'socketio/socket';

// MUI components
import { Box, TextField, IconButton } from '@mui/material';

// MUI icons
import SendIcon from '@mui/icons-material/Send';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

export default function ChatInputBox() {
  const isMobile = useSelector(state => state.global.isMobile);
  const inputRef = useRef(null);

  const userId = useSelector((state) => state.user.userId);
  const friendId = useSelector((state) => state.chats.friendId);
  const chatId = useSelector((state) => state.chats.chatSessionId);

  const [newMessage, setNewMessage] = useState('');

  function preventScroll() {
    document.body.style.overflow = 'hidden';
  }
  function restoreScroll() {
    document.body.style.overflow = '';
  }

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };
  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      const newChatMessage = {
        chatId,
        senderId: userId,
        receiverId: friendId,
        content: newMessage,
      };

      // Immediately update the chat messages state optimistically
      /*
      setChatMessages((prevMessages) => [...prevMessages, { ...newChatMessage, pending: true }]);
      */
    
      // Emit the message to the server
      socket.emit('sendMessage', newChatMessage, (response) => {
        if (response.status === 'ok') {
          // setChatMessages((prevMessages) =>
          //   prevMessages.map((msg) => (msg === newChatMessage ? { ...msg, pending: false } : msg))
          // );
        } else {
          // setChatMessages((prevMessages) => prevMessages.filter((msg) => msg !== newChatMessage));
          alert('Failed to send message. Please try again.');
        }
      });
      

      // Clear the input field and maintain focus
      setNewMessage('');
      inputRef.current.focus();
    }
  };

  const keyPress = (e) => {
    if(e.keyCode == 13){
        e.preventDefault()
        handleSendMessage()
    }
 }

  return (
    <>
      <TextField
        size='small'
        multiline
        maxRows={6}
        variant="outlined"
        placeholder='Type message...'
        fullWidth
        value={newMessage}
        onChange={handleInputChange}
        inputRef={inputRef}
        InputProps={{
          style: {
            borderRadius: "10px",
            fontSize: "14px",
          },
          onKeyDown: (e) => {
            preventScroll(); // Disable scrolling while a key is pressed
            if (!isMobile && e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          },
          onKeyUp: restoreScroll, // Re-enable scrolling when the key is released
        }}
        sx={{
          maxHeight: '120px',
          overflowY: 'auto',
          flexGrow: 1,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'var(--color-separator)', 
            },
            '&:hover fieldset': {
              borderColor: 'var(--color-accent)', 
            },
            '&.Mui-focused fieldset': {
              borderColor: 'var(--color-accent)', 
            },
          },
        }}
      />
      <IconButton
        color="primary"
        onMouseDown={(e) => e.preventDefault()} // Prevent default to avoid losing focus
        onClick={handleSendMessage}
        sx={{
          alignSelf: 'flex-end',
          mb: 1,
          color: 'black', // Set the send icon color to black
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)', // Add a slight hover effect
          },
        }}
      >
        <SendIcon />
      </IconButton>
    </>
  );
}