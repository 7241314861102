import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import settingService from 'api/services/settingService';

import { updateSettings } from 'app/slices/setting.slice';

import { Container, Tab, Tabs, Typography, Box, Button, Divider, RadioGroup, Radio, FormControl, FormControlLabel } from '@mui/material';
import { Opacity } from '@mui/icons-material';

import { delay } from 'scripts/delay';

export default function SaveSettingsButton({handleSave}) {
    const [saveIndicatorVisible, setSaveIndicatorVisible] = useState('0');
    const [saveIndicatorFade, setSaveIndicatorFade] = useState('0s');

    const saveIndicatorStyle = {
        opacity: saveIndicatorVisible,
        transition: saveIndicatorFade
    }

    const saveIndicatorAnimation = async () => {
        setSaveIndicatorVisible('1');
        await delay(500);
        setSaveIndicatorFade('0.2s');
        setSaveIndicatorVisible('0');
        await delay(200);
        setSaveIndicatorFade('0s');
    }

    const SaveButton = (<>
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                mt: 5
            }}
        >
            <Button 
            onClick={() => {handleSave(); saveIndicatorAnimation()}} 
            sx={{ 
                backgroundColor: 'var(--color-accent)', 
                borderRadius: '9999px',
                color: 'var(--color-main)', 
                textTransform: 'none',
                width: 90,
                opacity: 1,
                transition: '0.2s',
                '&:hover': {
                    backgroundColor: 'var(--color-accent)', 
                    opacity: 0.7,
                },
            }}
            >
                Save
            </Button>
            <Typography fontWeight={'bold'} mt={0.8} ml={2} sx={saveIndicatorStyle}>Settings saved!</Typography>
        </Box>  
    </>)

    return (<>
        {SaveButton}
    </>)
}