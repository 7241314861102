// React and Redux essentials
import React, { useEffect, useState, useReducer } from "react";
import { useNavigate } from "react-router-dom";

// MUI components
import {
  Box,
  Avatar,
  AvatarGroup,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Button,
  ButtonBase
} from "@mui/material";

// MUI icons
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PersonIcon from '@mui/icons-material/Person';

// State management (Redux)
import { useSelector, useDispatch } from "react-redux";
import { 
    deleteChat, 
    updateChatCategory,
    setChatSessionId, 
    setFriendId, 
    setFriendUserInfo,
    setSelectedCategoryId,
    setProfileDrawerVisible
} from 'app/slices/chat.slice';

// Components
import MoveChatDialog from "./dialogs/MoveChatDialog";

export default function ChatHeader({ chatUserInfo }) {
    const isMobile = useSelector(state => state.global.isMobile);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const friendId = useSelector(state => state.chats.friendId);

    // Profile drawer visibility state (mobile only)
    const profileDrawerVisible = useSelector(state => state.chats.profileDrawerVisible);
    useEffect(() => {void(0);},[profileDrawerVisible])

    // Action menu visibility handles 
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Dialog handles
    const [popupOpen, setPopupOpen] = useState(false);
    const handlePopupOpen = () => {
        handleClose();
        setPopupOpen(true);
    };
    const handlePopupClose = () => {
        setPopupOpen(false);
    };

    // Friend profile dawer (mobile only)
    const handleProfileDrawer = () => {
        handleClose();
        dispatch(setProfileDrawerVisible(true));
    }
    

    // Friend profile pic and name
    const [friendAvatar, setFriendAvatar] = useState('');
    const [friendName, setFriendName] = useState('');
    useEffect(() => {
        if (chatUserInfo[friendId] !== undefined) {
        // Apply avatar if any, otherwise use default
        if (chatUserInfo[friendId].images.length > 0) {
            setFriendAvatar(chatUserInfo[friendId].images[0].mediaUrl);
        } else {
            setFriendAvatar('unkown');
        }
        // Apply name
        setFriendName(chatUserInfo[friendId].name);
        //forceUpdate();
        }
        
    },[chatUserInfo, friendId])
    const ChattingUserInfo = (
        <Box sx={{ display: "flex", alignItems: "center", color: "white" }} >
            {/* Back to chat list button for mobile */}
            { isMobile && // Show close chat button for mobile only
            <IconButton onClick={() => dispatch(setChatSessionId(null))} >
                <CloseRoundedIcon fontSize='small' sx={{ ml: 1, color: '#b3b3b3' }} />
            </IconButton>
            }
            {/* Avatar */}
            <Avatar sx={{ height: "25px", width: "25px", my: 1, ml: 1 }} alt={friendName} src={friendAvatar} onClick={() => {dispatch(setProfileDrawerVisible(true));}}>
                <PersonIcon fontSize='inherit'/>
            </Avatar>

            {/* User name */}
            <Typography variant="body2" component="div" sx={{ ml: 1.25, }} onClick={() => {dispatch(setProfileDrawerVisible(true));}}>
                {friendName}
            </Typography> 
        </Box>
    )
    // Action menu button (3 dots to the right) 
    const MoreActionMenuButton = (
        <IconButton onClick={handleClick}>
            <MoreVertIcon sx={{ color: '#b3b3b3', mr: 1 }}/>
        </IconButton>
    )
    // Chat action menu
    const moreActionMenu = (
        <Menu
        id="chat-main-menu"
        anchorEl={anchorEl}
        elevation={1}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        >
            {isMobile && <MenuItem onClick={handleProfileDrawer}>View Profile</MenuItem> /* This only needed on mobile */} 
            <MenuItem onClick={handlePopupOpen}>Change Category</MenuItem>
            {/*<MenuItem onClick={handleDeleteChat}>Delete Chat</MenuItem>*/}
            <MenuItem >Block User</MenuItem>
        </Menu>
    )

    return <>
        <Box
            className="chat-main-header"
            elevation={0} 
            sx={isMobile ?
            { // Mobile layout
                display: "flex", 
                justifyContent: "space-between", 
                position: 'sticky', 
                top: 0, 
                zIndex: 1, 
                backgroundColor: 'var(--color-accent)', // Original: #121212
                borderBottom: 0.5, p: 0.5
            }
            :
            { // PC layout
                display: "flex", 
                justifyContent: "space-between", 
                position: 'sticky', 
                top: 0, 
                zIndex: 1, 
                backgroundColor: 'var(--color-accent)', // Original: #121212
                borderBottom: 0.5, p: 0.5
            }
            }
        >
            {/* User information */}
            {ChattingUserInfo}

            {/* Action menu dropdown */}
            {MoreActionMenuButton}
            {moreActionMenu}

            {/* Dialogs */}
            <MoveChatDialog 
                handleClick={handleClick} 
                handleClose={handleClose} 

                handlePopupOpen={handlePopupOpen} 
                handlePopupClose={handlePopupClose}

                popupOpen={popupOpen}
            />
            
        </Box>
    </>
}