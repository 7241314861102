import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Box,
  TextField,
  Button,
  ThemeProvider,
  createTheme,
  Autocomplete,
  Chip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EditSectionHeader from '../editUserProfile/EditSectionHeader';

import CustomTextField from './formComponents/CustomTextField';
import CustomSelect from './formComponents/CustomSelect';
import CustomSingleAutocomplete from './formComponents/CustomSingleAutocomplete';
import CustomMultiAutocomplete from './formComponents/CustomMultiAutocomplete';
import CustomDatePicker from './formComponents/CustomDatePicker';
import CustomEmojiPicker from './formComponents/CustomEmojiPicker';
import CustomSlider from './formComponents/CustomSlider';
import CustomMultiSelect from './formComponents/CustomMultiSelect';
import Custom5Autocomplete from './formComponents/Custom5Autocomplete';

import formConfig from './formsConfig';

const lightTheme = createTheme({
  palette: { mode: 'light', black: '#121212' },
});

const createFormComponent = (formType) => {
  const config = formConfig[formType];

  return ({
    initialValues = {},
    onSubmit,
    isEditMode,
    setEdit,
    handleSave,
    margin,
    onValuesChange,
  }) => (
    <ThemeProvider theme={lightTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
          {({ setFieldValue, values }) => {
            useEffect(() => {
              if (onValuesChange) {
                onValuesChange(values);
              }
            }, [values]);

            const renderField = (fieldConfig, index) => {
              switch (fieldConfig.type) {
                case 'text':
                  return (
                    <CustomTextField
                      key={fieldConfig.name + index}
                      fieldConfig={fieldConfig}
                      values={values}
                      setFieldValue={setFieldValue}
                      margin={margin}
                    />
                  );
                case 'select':
                  return (
                    <CustomSelect
                      key={fieldConfig.name + index}
                      fieldConfig={fieldConfig}
                      values={values}
                      setFieldValue={setFieldValue}
                      margin={margin}
                    />
                  );
                case 'multiSelect':
                  return (
                    <CustomMultiSelect
                      key={fieldConfig.name + index}
                      fieldConfig={fieldConfig}
                      values={values}
                      setFieldValue={setFieldValue}
                      margin={margin}
                    />
                  );
                case 'selectImage':
                  return (
                    <CustomSelect
                      key={fieldConfig.name + index}
                      fieldConfig={fieldConfig}
                      values={values}
                      setFieldValue={setFieldValue}
                      margin={margin}
                      image={true}
                    />
                  );
                case 'singleAutocomplete':
                  return (
                    <CustomSingleAutocomplete
                      key={fieldConfig.name + index}
                      fieldConfig={fieldConfig}
                      setFieldValue={setFieldValue}
                      values={values}
                      margin={margin}
                    />
                  );
                case 'multi5Autocomplete':
                  return (
                    <Custom5Autocomplete
                      key={fieldConfig.name + index}
                      fieldConfig={fieldConfig}
                      onSubmit={onSubmit}
                      values={values}
                      margin={margin}
                    />
                  );
                case 'multiAutocomplete':
                  return (
                    <CustomMultiAutocomplete
                      key={fieldConfig.name + index}
                      fieldConfig={fieldConfig}
                      onSubmit={onSubmit}
                      values={values}
                      margin={margin}
                    />
                  );
                case 'multiAutocompleteImage':
                  return (
                    <CustomMultiAutocomplete
                      key={fieldConfig.name + index}
                      fieldConfig={fieldConfig}
                      onSubmit={onSubmit}
                      values={values}
                      margin={margin}
                      image={true}
                    />
                  );
                case 'datePicker':
                  return (
                    <CustomDatePicker
                      key={fieldConfig.name + index}
                      fieldConfig={fieldConfig}
                      values={values}
                      setFieldValue={setFieldValue}
                      margin={margin}
                    />
                  );
                case 'emojiPicker':
                  return (
                    <CustomEmojiPicker
                      key={fieldConfig.name + index}
                      fieldConfig={fieldConfig}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  );
                case 'slider':
                  return (
                    <CustomSlider
                      key={fieldConfig.name + index}
                      fieldConfig={fieldConfig}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  );
                default:
                  return null;
              }
            };

            return (
              <Form>
                <Box display="flex" flexDirection="column" padding={isEditMode ? '2em' : 'none'}>
                  {isEditMode && (
                    <EditSectionHeader
                      section={`Edit ${formType.charAt(0).toUpperCase() + formType.slice(1)}`}
                      setEdit={setEdit}
                      handleSave={() => handleSave(values)}
                    />
                  )}
                  {config.map((fieldConfig, index) => renderField(fieldConfig, index))}
                </Box>
              </Form>
            );
          }}
        </Formik>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export const BasicsForm = createFormComponent('basics');
export const CareerForm = createFormComponent('career');
export const PhysicalForm = createFormComponent('physical');
export const PersonalityForm = createFormComponent('personality');
export const TheTeaForm = createFormComponent('theTea');

export const BasicsSearchForm = createFormComponent('basicsSearch');
export const CareerSearchForm = createFormComponent('careerSearch');
export const PhysicalSearchForm = createFormComponent('physicalSearch');
export const PersonalitySearchForm = createFormComponent('personalitySearch');
export const TheTeaSearchForm = createFormComponent('theTeaSearch');
