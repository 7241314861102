// React, Redux, DOM essentials
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 

// Redux store management functions
import { 
  updateCategories,
  setSelectedCategoryId,
  setChatSessionId
} from 'app/slices/chat.slice';

// Socket.IO essentials
import socket from 'socketio/socket';

// Backend service calls
import chatService from 'api/services/chatService';

// MUI components
import { 
  List, 
  ListItem, 
  ListItemAvatar, 
  ListItemText, 
  Typography,
  Box, 
  Avatar, 
  ButtonBase, 
  Tab, 
  Tabs 
} from '@mui/material';

// MUI icons
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// Components
import GroupChatList from 'components/groupchats/groupChatList/GroupChatList';

// Custom theme
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Custom functions
import { delay } from 'scripts/delay';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

export default function GroupChatListView({ group }) {
  const isMobile = useSelector(state => state.global.isMobile);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const groupChatId = useSelector(state => state.groupchats.groupChatSessionId); 
  const userId = useSelector(state => state.user.userId);
  const chats = useSelector(state => state.chats.groupChats);
  const chatUserAssociation = useSelector(state => state.chats.chatUserAssociation);

  const [hasUnreadMsg, setHasUnreadMsg] = useState(false);

  const getUnreadCount = async () => {
    try {
      const counter = await chatService.getUnreadMessageCount({ userId: userId, serviceCode: 1 });
      setHasUnreadMsg(counter.data.unreadCounter > 0);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getUnreadCount();
  }, []);

  /* Title and Back button */
  const handleBackButton = () => {
    dispatch(setSelectedCategoryId(null));
  };

  const TitleNBackButton = (
    <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
      {group && (
        <IconButton onClick={handleBackButton}>
          <ArrowBackIosIcon fontSize="small" sx={{ ml: 1 }} />
        </IconButton>
      )}
      <Typography variant="h6" component="div" sx={{ ml: 1 }} fontWeight="bold"></Typography>
    </Box>
  );

  /* Chat type menu */
  const [chatType, setChatType] = useState(1);
  const handleChatTypeChange = (event, newValue) => {
    setChatType(newValue);
  };
  
  const chatTypes = [
    { label: `M8 Chats ${hasUnreadMsg ? '•' : ''}` },
    { label: "Group Chats" },
  ];

  useEffect(() => {
    if (chatType === 0) {
      navigate('/chat');
    }
  }, [chatType, navigate]);

  const ChatTypeMenu = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: 40,
        height: 40,
        mb: 2
      }}
    >
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={chatType}
        onChange={handleChatTypeChange}
        aria-label="Chat Type Tabs"
        textColor="var(--color-text)"
        indicatorColor="var(--color-separator)"
        sx={{
          color: 'var(--color-accent)',
          '.MuiTab-textColorPrimary': { color: 'var(--color-text)' },
          '.MuiTabs-indicator': { backgroundColor: 'var(--color-separator)' },
        }}
      >
        {chatTypes.map((option, index) => (
          <Tab key={index} label={option.label} sx={{ alignItems: 'start', textTransform: "none" }} />
        ))}
      </Tabs>
    </Box>
  );

  return (
    <ThemeProvider theme={lightTheme}>
      <Box
        className="chat-list"
        sx={
          isMobile
            ? {
                width: '100%',
                height: '100%',
                display: groupChatId === null ? "visible" : "none"
              }
            : {
                mt: 2,
                border: '2px solid var(--color-accent)',
                borderRadius: 'var(--border-radius-main)',
                width: '420px',
                height: '85vh',
                '@media (max-height: 650px)': {
                  height: '80vh'
                },
                '@media (max-height: 508px)': {
                  height: '75vh'
                }
              }
        }
      >
        <Box sx={{ mt: 0, width: '100%' }}>
          {ChatTypeMenu}
          <GroupChatList chatTypeMenuVisible={true} />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
