// React essentials
import React, { useState, useRef, useEffect, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  deleteChat,
  updateChatCategory,
  setChatSessionId,
  setFriendId,
  setFriendUserInfo,
  setSelectedCategoryId,
} from 'app/slices/chat.slice';

// MUI components
import {
  Avatar,
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  Button,
  TextField,
} from '@mui/material';

// MUI icons
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';

// MUI style wrapper
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Custom components
import ChatMessage from '../chatMessage/ChatMessage';
import ChatInputBox from './ChatInputBox';
import ChatHeader from './ChatHeader';

// Backend service calls
import chatService from 'api/services/chatService';
import socket from 'socketio/socket';

// Custom scripts
import { delay } from 'scripts/delay';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

function preventScroll() {
  document.body.style.overflow = 'hidden';
}

function restoreScroll() {
  document.body.style.overflow = '';
}

function ChatWindow() {
  const isMobile = useSelector((state) => state.global.isMobile);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFocus = useSelector((state) => state.global.appOnFocus);

  // Visibility control states for mobile layout
  const [chatVisible, setChatVisible] = useState(true);

  // User & Chat data from Redux store
  const userId = useSelector((state) => state.user.userId);
  const friendId = useSelector((state) => state.chats.friendId);
  const chatId = useSelector((state) => state.chats.chatSessionId);
  const chatUserAssociationId = useSelector(
    (state) =>
      state.chats.chatUserAssociation[chatId] &&
      state.chats.chatUserAssociation[chatId]._id
  );
  const chatUserAssociationCategoryId = useSelector(
    (state) =>
      state.chats.chatUserAssociation[chatId] &&
      state.chats.chatUserAssociation[chatId].categoryId
  );
  const chatUserInfo = useSelector((state) => state.chats.friendUserInfo);
  const categories = useSelector((state) => state.chats.categories);

  const [chat, setChat] = useState();
  const [chatMessages, setChatMessages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [newMessage, setNewMessage] = useState('');
  const messageListRef = useRef(null);
  const inputRef = useRef(null);

  // Chat window layout
  const mobileLayout = {
    width: '100%',
    height: '93vh',
    display: chatId !== null && chatVisible ? 'visible' : 'none',
  };
  const desktopLayout = {
    mt: 2,
    border: '2px solid var(--color-accent)',
    borderRadius: 'var(--border-radius-main)',
    width: '780px',
    height: '85vh',
    display: 'flex', // Ensure the chat window takes up the full available height and the message window can scroll
    flexDirection: 'column',
    '@media (max-height: 650px)': {
      height: '80vh',
    },
    '@media (max-height: 508px)': {
      height: '75vh',
    },
    overflow: 'hidden', // Prevent overflow from the entire chat window
  };
  
  // Make sure the message window is scrollable
  const chatMessageWindowStyle = {
    flex: 1, // This ensures the message window takes up remaining space
    overflowY: 'auto', // Enable scrolling for the messages
    mx: 1,
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
    },
    borderBottomRightRadius: 'var(--border-radius-main)',
    borderBottomLeftRadius: 'var(--border-radius-main)',
  };
  
  // Update the input container to stay fixed at the bottom
  const chatMessageInputStyle = {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    background: 'var(--color-main)',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    padding: 1,
    gap: 1,
  };

  // Adjust viewport height on resize and focus events
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    // Handle viewport changes to keep input at the bottom
    const handleFocus = () => {
      window.scrollTo(0, document.body.scrollHeight); // Ensure the input stays in view
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('focusin', handleFocus); // Adjusts view when input is focused

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('focusin', handleFocus);
    };
  }, []);

  // Initialize chats and user profiles based on chat ID
  useEffect(() => {
    if (!chatId) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    Promise.all([
      chatService.getChatUserInfo(chatId),
      chatService.getChatMessages(chatId),
    ])
      .then(async ([userInfoRes, messagesRes]) => {
        dispatch(setFriendUserInfo(userInfoRes.data));
        setChat(messagesRes.data.chat);

        // Set the chatting user ID
        for (let i = 0; i < Object.keys(userInfoRes.data).length; i++) {
          if (userInfoRes.data[Object.keys(userInfoRes.data)[i]].userId !== userId) {
            dispatch(setFriendId(userInfoRes.data[Object.keys(userInfoRes.data)[i]].userId));
            break;
          }
        }

        setChatMessages([]);
        await delay(10);
        setChatMessages(messagesRes.data.messages);

        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, [chatId, dispatch, userId]);

  // For scrolling to bottom of message window to show latest message
  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [chatMessages]);

  // Real time chat updates via sockets
  useEffect(() => {
    const messageListener = (newChatMessage) => {
      if (newChatMessage.chatId === chatId) {
        setChatMessages(prevMessages => [...prevMessages, newChatMessage]);

        // Scroll to bottom of message window to show latest message
        if (messageListRef.current) {
          messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
      }
    };

    socket.on('receiveMessage', messageListener);
    socket.on('messageSent', messageListener);

    return () => {
      socket.off('receiveMessage', messageListener);
      socket.off('messageSent', messageListener);
    };
  }, [chatId]);

  // **Added: useEffect for Periodic Message Fetching Every 5 Seconds**
  useEffect(() => {
    if (!chatId) return;

    const intervalId = setInterval(() => {
      chatService.getChatMessages(chatId)
        .then((messagesRes) => {
          setChatMessages(messagesRes.data.messages);
        })
        .catch((err) => {
          console.error('Error fetching chat messages:', err);
        });
    }, 3000); // 3000 milliseconds = 5 seconds

    // Cleanup interval on component unmount or chatId change
    return () => clearInterval(intervalId);
  }, [chatId]);

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      const newChatMessage = {
        chatId,
        senderId: userId,
        receiverId: friendId,
        content: newMessage,
      };

      // Immediately update the chat messages state optimistically
      setChatMessages((prevMessages) => [...prevMessages, { ...newChatMessage, pending: true }]);

      // Emit the message to the server
      socket.emit('sendMessage', newChatMessage, (response) => {
        if (response.status === 'ok') {
          setChatMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg === newChatMessage ? { ...msg, pending: false } : msg
            )
          );
        } else {
          setChatMessages((prevMessages) =>
            prevMessages.filter((msg) => msg !== newChatMessage)
          );
          alert('Failed to send message. Please try again.');
        }
      });

      // Clear the input field and maintain focus
      setNewMessage('');
      if (inputRef.current) inputRef.current.focus();
    }
  };

  // Chat delete handler
  const handleDeleteChat = () => {
    const categoryId = chatUserAssociationCategoryId;
    dispatch(setChatSessionId(null));
    chatService.removeChat({ chatId, chatUserAssociationId, categoryId })
      .then((res) => {
        const removedChatId = res.data.chatId;
        const removedCategoryId = res.data.categoryId;
        dispatch(deleteChat({ chatId: removedChatId, categoryId: removedCategoryId }));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Accepting and applying chatId from URL parameters if any
  let urlParams = useParams();
  useEffect(() => {
    if (urlParams.chatId !== undefined) {
      dispatch(setChatSessionId(urlParams.chatId));
    }
  }, [urlParams.chatId, dispatch]);

  /* Empty chat */
  const emptyChatId = (obj) => {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  };

  const NoChat = (
    <Box
      className="chat-main-empty"
      sx={
        isMobile
          ? { width: '100%', height: '100%' }
          : {
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              width: '100%',
              height: '100%',
              fontSize: '100px',
              opacity: 1,
            }
      }
    >
      {!isMobile && <ForumRoundedIcon fontSize="inherit" />}
    </Box>
  );

  /* Chat messages */
  let vh = window.innerHeight * 0.01; 
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  const ChatMessageWindow = (mesgRef, mesgs, usrInfo, uID) => {
    return (
      <Box
        className="chat-main-messages"
        sx={
          isMobile
            ? {
                mx: 1,
                flex: 0.9,
                maxHeight: '100vh', // Fallback for old browsers
                maxHeight: `calc(var(--vh, 1vh) * 100)`, // Corrected to use 'vh'
                overflowY: 'auto', // Ensure scroll is enabled
                scrollbarWidth: 'none', // Hide the scrollbar in Firefox
                '&::-webkit-scrollbar': {
                  width: '0.5em',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'transparent', // Hide the scrollbar thumb
                },
              }
            : {
                mx: 1,
                flex: 1,
                overflowY: 'auto', // Ensure scroll is enabled
                scrollbarWidth: 'none', // Hide the scrollbar in Firefox
                '&::-webkit-scrollbar': {
                  width: '0.5em',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'transparent', // Hide the scrollbar thumb
                },
                borderBottomRightRadius: 'var(--border-radius-main)',
                borderBottomLeftRadius: 'var(--border-radius-main)',
              }
        }
        ref={mesgRef}
      >
        <>
          {mesgs.map((message, index) => (
            <ChatMessage 
              key={index} 
              userId={uID} 
              chatUserInfo={usrInfo} 
              message={message} 
            />
          ))}
          <Box mb={2}></Box> {/* Add a bottom margin below last message to give space for Message Input Field */}
        </>
      </Box>
    );
  };

  /* Chat input field */
  const ChatMessageInput = (
    <Box
      className="chat-main-input"
      sx={
        isMobile ? 
            { 
              position: 'fixed',
              bottom: 5,
              width: '100%',
              background: 'var(--color-main)',
              zIndex: 2,
              display: 'flex',
              alignItems: 'center',
              padding: 1,
              gap: 1,
            }
          : {
              position: 'sticky',
              bottom: 0,
              background: 'var(--color-main)',
              zIndex: 2,
              display: 'flex',
              alignItems: 'center',
              padding: 1,
              gap: 1,
            }
      }
    >
      <ChatInputBox 
        newMessage={newMessage} 
        setNewMessage={setNewMessage} 
        handleSendMessage={handleSendMessage} 
        inputRef={inputRef} 
      />
    </Box>
  );

  /* Loading spinner */
  const LoadingSpinner = (
    <Box
      className="chat-main-empty"
      sx={
        isMobile
          ? { width: '100%', height: '100%' }
          : {
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              width: '100%',
              height: '100%',
              fontSize: '100px',
              opacity: 1,
            }
      }
    >
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    </Box>
  );

  if (isLoading) {
    return (
      <Box className="chat-main" sx={isMobile ? mobileLayout : desktopLayout}>
        {LoadingSpinner}
      </Box>
    );
  }

  /* Empty chat page */
  if (emptyChatId(chatId)) {
    return (
      <Box className="chat-main" sx={isMobile ? mobileLayout : desktopLayout}>
        {NoChat}
      </Box>
    );
  }

  return (
    <Box className="chat-main" sx={isMobile ? mobileLayout : desktopLayout}>
      {isLoading ? LoadingSpinner : (
        <ThemeProvider theme={lightTheme}>
          <Box
            sx={{
              height: `100%`,
              width: '100%',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* Chat header */}
            {chat && <ChatHeader chatUserInfo={chatUserInfo} />}

            {/* Main chat window */}
            {chatUserInfo && chatMessages && (
              <>
                {ChatMessageWindow(messageListRef, chatMessages, chatUserInfo, userId)}
                {ChatMessageInput}
              </>
            )}
          </Box>
        </ThemeProvider>
      )}
    </Box>
  );
}

export default ChatWindow;
