import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

import userService from 'api/services/userService';
import { updateUser } from 'app/slices/user.slice';

export default function NotificationSettings() {
  const dispatch = useDispatch();

  const [emailNotifications, setEmailNotifications] = useState(userService.getCurrentUser().emailNotifications);

  useEffect(() => {
    // Fetch the emailNotifications value from the user database
    userService.getCurrentUser()
      .then(userData => {
        console.log("Fetched user data:", userData);
        setEmailNotifications(userData.emailNotifications ? 'enabled' : 'disabled');
      })
      .catch(error => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  // Radio button change handler
  const handleChange = (event) => {
    const newValue = event.target.value;
    console.log("handleChange: Selected", newValue);
    setEmailNotifications(newValue);

    const enabled = newValue === 'enabled';

    console.log("handleChange: Initiating updateEmailNotifications with value =", enabled);
    userService.updateEmailNotifications(enabled)
      .then((res) => {
        console.log("handleChange: Received response from updateEmailNotifications:", res);
        dispatch(updateUser({ emailNotifications: res.emailNotifications }));
        console.log("handleChange: Dispatched updateUser with emailNotifications =", res.emailNotifications);
      })
      .catch((error) => {
        console.error("handleChange: Failed to update email notifications.", error);
      });
  };

  return (
    <Box>
      <Typography variant='h6' fontWeight='bold' mt={3}>
        Email Notifications
      </Typography>
      <Typography fontWeight='normal' sx={{ opacity: 0.5 }}>
        Enable or disable email notifications for unread messages.
      </Typography>
      <FormControl component="fieldset" sx={{ mt: 2 }}>
        <FormLabel component="legend">Status</FormLabel>
        <RadioGroup
          aria-label="email-notifications"
          name="emailNotifications"
          value={emailNotifications}
          onChange={handleChange}
          row
        >
          <FormControlLabel value="enabled" control={<Radio color="primary" />} label="Enabled" />
          <FormControlLabel value="disabled" control={<Radio color="primary" />} label="Disabled" />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}


