// React essentials
import React, { useEffect, useState, useRef } from 'react';

// Redux essentials
import { useSelector } from 'react-redux';

// Socket.io
import socket from 'socketio/socket';

// MUI components
import { Box, TextField, IconButton } from '@mui/material';

// MUI icons
import SendIcon from '@mui/icons-material/Send';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

export default function GroupChatInputBox() {
  const isMobile = useSelector(state => state.global.isMobile);
  const inputRef = useRef(null);

  const userId = useSelector(state => state.user.userId);
  const groupChat = useSelector(state => state.groupchats.groupChatDetail);

  const [newMessage, setNewMessage] = useState('');

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      const newGroupChatMessage = {
        groupChat: groupChat,
        content: newMessage,
        senderId: userId,
      };
      socket.emit("sendGroupMessage", {...newGroupChatMessage, userId});
      setNewMessage('');
    }
  };

    function preventScroll() {
      document.body.style.overflow = 'hidden';
    }
    function restoreScroll() {
      document.body.style.overflow = '';
    }

  const keyPress = (e) => {
    if(e.keyCode == 13){
        e.preventDefault()
        handleSendMessage()
    }
 }

  return (<>

  {isMobile && (<>
      {/* Left edge bend */}
      <Box
        sx={{
          position: 'fixed',
          backgroundColor: '#00000000',
          bottom: 67,
          left: 7,
          height: '50px',
          width: '50px',
          borderBottomLeftRadius: 'var(--border-radius-main)',
          'box-shadow': '0 15px var(--color-main)',
          zIndex: 2,
        }}
      />
      {/* Right edge bend */}
      <Box
        sx={{
          position: 'fixed',
          backgroundColor: '#00000000',
          bottom: 67,
          right: 7,
          height: '50px',
          width: '50px',
          borderBottomRightRadius: 'var(--border-radius-main)',
          'box-shadow': '0 15px var(--color-main)',
          zIndex: 2,
        }}
      />
    </>)}

    <Box sx={{ mx: 2, my: 1, width: '100%' }} display="flex" alignItems="center">

      {/* Text input field */}
      <TextField
        size='small'
        multiline
        maxRows={6}
        variant="outlined"
        placeholder='Type message...'
        fullWidth
        value={newMessage}
        onChange={handleInputChange}  
        InputProps={{
            style: {
              borderRadius: "10px",
              fontSize: "14px",
            },
            onKeyDown: (e) => {
              preventScroll(); // Disable scrolling while a key is pressed
              if (!isMobile && e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
          },
          onKeyUp: restoreScroll, // Re-enable scrolling when the key is released
        }}
        sx={{
          maxHeight: '120px',
          overflowY: 'auto',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'var(--color-separator)',
            },
            '&:hover fieldset': {
              borderColor: 'var(--color-accent)', 
            },
            '&.Mui-focused fieldset': {
              borderColor: 'var(--color-accent)', 
            },
          },
        }}
      />

      {/* Send message button */}
      <IconButton
        sx={{ color: 'var(--color-accent)', paddingRight: 0 }}
        onClick={handleSendMessage}
      >
        <ArrowCircleRightIcon fontSize="large" />
      </IconButton>
    </Box>
  </>);
}