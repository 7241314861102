import { createSlice } from '@reduxjs/toolkit';
import { update } from 'lodash';
import { isMobile } from 'scripts/mobileCheck';

// Inital states
const initialState = {
    isMobile: isMobile(),
    appOnFocus: true,
    isWebView: false,
}

// Slice
const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
      updateIsMobile: (state, action) => {
        const isMobile = action.payload;
        state.isMobile = isMobile ?? state.isMobile;
      },
      updateAppOnFocus: (state, action) => {
        const onFocus = action.payload;
        state.appOnFocus = onFocus ?? state.appOnFocus;
      },
      updateIsWebView: (state, action) => {
        const isWebView = action.payload;
        state.isWebView = isWebView ?? state.isWebView;
      },
    },
});
export const { updateIsMobile, updateAppOnFocus, updateIsWebView } = globalSlice.actions;
export default globalSlice.reducer;