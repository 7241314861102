

import { createSlice } from '@reduxjs/toolkit';
import userService from 'api/services/userService';

const initialState = {
  role: "",
  userId: "",
  images: [],
  name: "",
  description: "",
  basics: {
    currentCity: "",
    genderIdentity: "",
    stageOfLife: "",
    religion: "",
    dateOfBirth: "2023-12-11T02:22:38.407Z"
  },
  career: {
      schools: [],
      classes: [],
      occupation: [],
      company: "",
      income: 0
  },
  physical: {
      heightFt: 0,
      heightIn: 0,
      weight: 0,
      ethnicity: "",
      eyeColor: "",
      hairColor: "",
      bodyType: "",
      disability: []
  },
  personality: {
      personalityType: "",
      introExtroVert: [],
      morningNight: [],
      favoriteEmojis: [],
      relationshipIntent: "",
      friendIntent: "",
      idealFD8: [],
      idealRD8: [],
      coreValues: []
  },
  theTea: {
      interests: [],
      drugsAlcohol: [],
      music: [],
      tvMovies: [],
      comedians: [],
      sportsTeams: [],
      citiesVisitedLived: [],
      politics: "",
      sexuality: "",
      roommatePreference: ""
  },
  referredCount: 0,
  emailNotifications: true
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action) => {
      const { userId, images, name, description, referralCode, basics, career, physical, personality, theTea, emailNotifications } = action.payload;
    
      state.userId = userId ?? state.userId
      state.images = images ?? state.images;
      state.name = name ?? state.name;
      state.description = description ?? state.description;
      state.referralCode = referralCode ?? state.referralCode;
      state.basics = basics ?? state.basics;
      state.career = career ?? state.career;
      state.physical = physical ?? state.physical;
      state.personality = personality ?? state.personality;
      state.theTea = theTea ?? state.theTea;

      if (emailNotifications !== undefined) {
        state.emailNotifications = emailNotifications;
      }
    },
    updateRole: (state, action) => {
      const role = action.payload;
      state.role = role;
    },

    updateReferredCount: (state, action) => {
      const referredCount = action.payload;
      state.referredCount = referredCount;
    },
    setEmailNotifications: (state, action) => {
      state.emailNotifications = action.payload;
    }
  },
});

export const { updateUser, updateRole, updateReferredCount, setEmailNotifications } = userSlice.actions;

export const updateEmailNotifications = (enabled) => async (dispatch) => {
  try {
    await userService.updateEmailNotifications(enabled);
    dispatch(setEmailNotifications(enabled));
  } catch (error) {
    console.error('Error updating email notifications:', error);
  }
};

export default userSlice.reducer;


