import React, { useState, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';


import { Box } from '@mui/material';

import { updateIsWebView } from 'app/slices/global.slice';

import { openedInWebView } from 'scripts/webViewCheck';


export default function UnAuthLayout() {
  const isWebView = useSelector(state => state.global.isWebView);

  const dispatch = useDispatch();

  useEffect(() => {
    if (openedInWebView() === true) {
      dispatch(updateIsWebView(true));
    } else {
      dispatch(updateIsWebView(false));
    }
  },[]);

  useEffect(() => {
    isWebView && console.log("Opened in WebView")
    !isWebView && console.log("Opened in Browser")
  },[isWebView]);

  return (
    <Box className="app-container">
      <Outlet />
    </Box>
  );
}