import React, { useEffect, useState, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { 
  List,
  ListItem, 
  ListItemAvatar, 
  Avatar, 
  ListItemText, 
  Typography, 
  Box, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  TextField, 
  IconButton, 
  Button 
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { textFieldOverrides } from 'styles/MUIOverriders/textFieldStyleOverrider';
import { outlinedInputOverrides } from 'styles/MUIOverriders/outlinedInputStyleOverrides'; 
import { dialogButtonRegular } from "styles/dialogButtonStyles";
import { dialogButtonFocus } from "styles/dialogButtonStyles";

import chatService from 'api/services/chatService';

import { 
  addCategory,
  setSelectedCategoryId 
} from 'app/slices/chat.slice'
import CategoryListItem from './CategoryListItem';


const customTheme = createTheme({
  components: {
    ...textFieldOverrides,
    ...outlinedInputOverrides,
  },
});

const mobileStyle = {
  top: '-25%', // adjust this value as needed

};

function CategoryList({ chatTypeMenuVisible }) {
  const dispatch = useDispatch();
  const isMobile = useSelector(state => state.global.isMobile);

  const userId = useSelector(state => state.user.userId);
  const categories = useSelector(state => state.chats.categories);

  // "Add new category" Dialog handler
  const [isAddCategoryDiaOpen, setAddCategoryDiaOpen] = useState(false);
  const handleOpenAddCategoryDia = () => {
    setAddCategoryDiaOpen(true);
  };
  const handleCloseAddCategoryDia = () => {
    setAddCategoryDiaOpen(false);
  };

  // Component rerender
  /*const [update, forceUpdate] = useState(1);
  useEffect(() => {

  },[update])*/

  // Category title & New Category button
  const CategoryListTitle = (
    <Box
        className="category-list"
        sx={
          isMobile ?
          { // Mobile layout
            
          }
          :
          { // PC layout
            mt: 0,
            mb: 2,
          }
        }
      >
      <Box 
          className="category-title"
          sx={
            isMobile ?
            { // Mobile layout
              display: "flex", 
              width: "100%", 
              alignItems: "center", 
              justifyContent: "space-between" 
            }
            :
            { // PC layout
              display: "flex", 
              width: "100%", 
              alignItems: "center", 
              justifyContent: "space-between" 
            }
          }
        >
        
          {/* Title */}
          <Typography variant="h5" component="div" sx={{ ml: 2, fontWeight: 'bold' }}>
            Chat Categories
          </Typography>

          {/* Category addition button */}
          <IconButton size="small" onClick={handleOpenAddCategoryDia} sx={{ mr: 0 }}>
            <AddIcon />
          </IconButton>
      </Box>
    </Box>
  )

  // Add new category dialog
  const [newCategoryName, setNewCategoryName] = useState('');
  const handleAddCategory = async () => {
    if(newCategoryName) {
      chatService.createCategory({ userId, name: newCategoryName }).then(res => {
        dispatch(addCategory(res.data));
        window.location.reload();
      })
      handleCloseAddCategoryDia();
    }
  };
  const NewCategoryDialogue = (
    <Dialog open={isAddCategoryDiaOpen} onClose={handleCloseAddCategoryDia} sx={mobileStyle}>
        <DialogTitle sx={{ fontSize: "1rem", mb: 2 }}>Add a new Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="categoryName"
            label="Category Name"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
          />
        </DialogContent>
        
          {/* Cancel / Confirm addition of category (buttons) */}
          <DialogActions>
            <Button className="category-add-cancel" sx={dialogButtonRegular} onClick={handleCloseAddCategoryDia}>Cancel</Button>
            <Button className="category-add-confirm" sx={dialogButtonFocus} size="small" variant="contained" onClick={handleAddCategory}>Add</Button>
          </DialogActions>
    </Dialog>
  )

  // Category list
  const CategoryList = (
    <List
      sx={isMobile ?
        { // Mobile layout
          border: '2px solid var(--color-accent)',
          borderRadius: 'var(--border-radius-main)',
          mt: 2,
          mx: 1,
          overflowY: 'scroll',
          height: chatTypeMenuVisible ? '78vh' : '80vh',
          '@media (max-height: 870px)': {
            height: '75vh',
          },
          '@media (max-height: 700px)': {
            height: '70vh',
          },
        }
        :
        { // PC layout
          overflowY: 'scroll',
          height: '100%'
        }
      }
    >
      {Object.values(categories).map(category => (
          <CategoryListItem key={category._id} category={category}/>
      ))}
    </List>
  )


  return (
    <ThemeProvider theme={customTheme}>
    <Box className="category-container" sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
      <Box
        className="category"
        sx={
          isMobile ?
          {
            width: '100%',
            display: 'flex',
            justifyContent: "center",
            flexDirection: 'column'
          }
          :
          {
            width: '100%',
            borderRadius: 'var(--border-radius-main)',
            display: 'flex',
            justifyContent: "center",
            flexDirection: 'column',
            height: chatTypeMenuVisible ? 'calc(85vh - 56px)' : 'calc(85vh - 35px)',
            '@media (max-height: 650px)': {
              height: chatTypeMenuVisible ? 'calc(80vh - 56px)' : 'calc(80vh - 35px)'
            },
            '@media (max-height: 508px)': {
              height: chatTypeMenuVisible ? 'calc(75vh - 56px)' : 'calc(75vh - 35px)'
            },
          }
        }
      >

        {/* Title container & category add button*/}
        {CategoryListTitle}

        {/* Category list */}
        {CategoryList}


        {/* Category addition dialog */}
        {NewCategoryDialogue}

      </Box>
    </Box>
    </ThemeProvider>
  );
}

export default CategoryList;
