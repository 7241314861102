import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import CancelIcon from '@mui/icons-material/Cancel';
import { Box, IconButton, Button, Drawer, styled } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});


const DrawerHandle = styled('div')(({ theme }) => ({
  width: 30,
  height: 4,
  backgroundColor: theme.palette.grey[300],
  borderRadius: 3,
  position: 'relative',
  top: 8,
  left: 'calc(50% - 15px)',
  marginBottom: 8,
}));

const DrawerContent = styled('div')({
  overflowY: 'auto',
  height: '100%', // Use 100% of the Drawer's content area height
});

const RightAlignedIconButton = styled(IconButton)({
});

export default function  BottomDrawer({ isDrawerOpen, toggleDrawer, content, height, buttonAbsolute }) {
  const isMobile = useSelector(state => state.global.isMobile);

  return (
    <ThemeProvider theme={lightTheme}>
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          style: { height: height ?? '75vh', borderTopLeftRadius: 16, borderTopRightRadius: 16 }
        }}
      >
        <div
          role="presentation"
          style={{ width: "100%", /*height: isMobile ? 'unset' : '100%'*/ }}
        >
          <Box sx={{ width: "100%", display: 'flex', justifyContent: 'flex-end' }}>
            <RightAlignedIconButton
              size="large"
              onClick={toggleDrawer(false)}
              color="inherit"
              sx={buttonAbsolute &&
                { position: "absolute", top: 5, right: 5, zIndex: 100 } 
              }
            >
              <CancelIcon />
            </RightAlignedIconButton>
          </Box>
          <DrawerContent>
            {content}
          </DrawerContent>
        </div>
      </Drawer>
    </ThemeProvider>
  );
};