import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 

import socket from 'socketio/socket';

import { Typography, Box, IconButton, Tabs, Tab } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import ChatList from './chatList/ChatList';
import CategoryList from './categoryList/CategoryList';

import { 
  updateCategories,
  setSelectedCategoryId,
  setChatSessionId
} from 'app/slices/chat.slice';

import chatService from 'api/services/chatService';
import groupChatService from 'api/services/groupchatService';

import { delay } from 'scripts/delay';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

function ChatListView({ group }) {
  const isMobile = useSelector(state => state.global.isMobile);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedCategoryId = useSelector(state => state.chats.selectedCategoryId);
  const categories = useSelector(state => state.chats.categories);
  const category = useSelector(state => state.chats.categories[selectedCategoryId]);
  const chatId = useSelector(state => state.chats.chatSessionId); 
  const userId = useSelector(state => state.user.userId);
  const chats = useSelector(state => state.chats.chats[selectedCategoryId]);
  const chatUserAssociation = useSelector(state => state.chats.chatUserAssociation);

  // For showing indicator in Chat Type Menu when user has unread messages in one or more Group Chats
  const [hasUnreadMsg, setHasUnreadMsg] = useState(false);

  const getUnreadCount = async () =>{
    try {
      // serviceCode = 1: Count unread messages in ALL chats
      const counter = await groupChatService.getUnreadMessageCount({ userId: userId, serviceCode: 1 });
      setHasUnreadMsg(counter.data.unreadCounter > 0);
    } catch (error) {
      console.error('Error fetching unread count:', error);
    }
  };

  useEffect(() => {
    getUnreadCount();
  }, []);

  /* Title and Back button */
  const handleBackButton = () => {
    dispatch(setSelectedCategoryId(null));
    // If needed, emit a refresh signal or perform additional actions
    // socket.emit('refreshChatSignal',{});
  };

  const TitleNBackButton = (
    <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
      {group && (
        <IconButton 
          onClick={handleBackButton}
          onTouchStart={handleBackButton} // Add touch handling for iOS devices
        >
          <ArrowBackIosIcon fontSize='small' sx={{ ml: 1 }} />
        </IconButton>
      )}
      <Typography variant="h6" component="div" sx={{ ml: 1 }} fontWeight="bold">
        {category && category.name}
      </Typography>
    </Box>
  );

  /* Chat type menu - Always Visible */
  const [chatType, setChatType] = useState(0);

  const handleChatTypeChange = (event, newValue) => {
    setChatType(newValue);
  };

  const chatTypes = [
    { label: "M8 Chats" },
    { label: `Group Chats ${hasUnreadMsg ? '•' : ''}` },
  ];

  useEffect(() => {
    switch (chatType) {
      case 0:
        // Stay on M8 Chats
        break;
      case 1:
        navigate('/group-chat');
        break;
      default:
        break;
    }
  }, [chatType, navigate]);

  const ChatTypeMenu = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: 40,
        height: 40,
        mb: 2,
        gap: 2, // Adds space between tabs
      }}
    >
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={chatType}
        onChange={handleChatTypeChange}
        aria-label="Chat Type Tabs"
        textColor="var(--color-text)"
        indicatorColor="var(--color-separator)"
        sx={{ 
          color: 'var(--color-accent)',
          borderRight: 0, 
          borderColor: 'var(--color-accent)',
          '.MuiTab-textColorPrimary': { color: 'var(--color-text)' },
          '.MuiTabs-indicator': { backgroundColor: 'var(--color-separator)' },
        }}
      >
        {chatTypes.map((option, index) => 
          <Tab key={index} label={option.label} sx={{ alignItems: 'start', textTransform: "none" }} />
        )}
      </Tabs>
    </Box>
  );

  return (
    <ThemeProvider theme={lightTheme}>
      <Box
        className="chat-list"
        sx={
          isMobile
          ? { // Mobile layout
              width: '100%',
              height: '100%',
              display: chatId === null ? "block" : "none"
            }
          : { // PC layout
              mt: 2,
              border: '2px solid var(--color-accent)',
              borderRadius: 'var(--border-radius-main)',
              width: '420px',
              height: '85vh',
              '@media (max-height: 650px)': {
                height: '80vh'
              },
              '@media (max-height: 508px)': {
                height: '75vh'
              },
            }
        }
      >
        <Box sx={{ mt: 0, width: '100%' }}>
          {/* Show chat list when category is selected; Show category list if not */}
          {Object.keys(categories).length && selectedCategoryId === null ? (
            <>
              {ChatTypeMenu}
              <CategoryList chatTypeMenuVisible={true} />
            </>
          ) : (
            <>
              {ChatTypeMenu}
              {TitleNBackButton}
              <ChatList chatTypeMenuVisible={true} />
            </>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ChatListView;
