// React essentials
import React, { useState, useEffect, useReducer } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

// MUI components
import { Box, CircularProgress } from '@mui/material';

// Nav bar
import NavBar from '../navBar/NavBar';

// Redux state management
import { useDispatch, useSelector } from 'react-redux';
import { updateChats, updateCategories, updateDefaultCategory, updateChatUserAssociation, addNewChat, updateLastMessages, updateLastMessage } from 'app/slices/chat.slice'
import { updateGroupChats, updateGroupChatUserAssociations } from 'app/slices/groupchat.slice';
import { updateUser, updateRole, updateReferredCount } from 'app/slices/user.slice';
import { updateSettings } from 'app/slices/setting.slice';
import { updateEvents } from 'app/slices/events.slice';
import { updateIsWebView } from 'app/slices/global.slice';

// Backend service calls
import userService from "api/services/userService";
import chatService from 'api/services/chatService';
import groupChatService from 'api/services/groupchatService';
import eventService from 'api/services/eventService';
import settingService from 'api/services/settingService';
import socket from 'socketio/socket';

// Hooks
import WindowFocusHandler from 'hooks/WindowFocusHandler';

// Push notifications
import { sendPushNotification } from 'webpush/push';

// Scripts
import { openedInWebView } from 'scripts/webViewCheck';

export default function AuthLayout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [updateForced, forceUpdate] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  // WebView/Browser check
  const isWebView = useSelector(state => state.global.isWebView);
    useEffect(() => {
      if (openedInWebView() === true) {
        dispatch(updateIsWebView(true));
      } else {
        dispatch(updateIsWebView(false));
      }
    },[]);
    useEffect(() => {
      isWebView && console.log("Page mode: WebView")
      !isWebView && console.log("Page mode: Browser")
    },[isWebView]);

  // Window resize listener
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // User account auth check
  const checkAuthentication = async () => {
    try {
      const response = await userService.checkToken();
      if (response.status === 200) {
        dispatch(updateRole(response.data.role))
        setIsAuthenticated(true);
        return true;
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      console.error(err.response);
      
      return false;
    }
  };
  useEffect(() => {
    checkAuthentication().then(isAuth => {
      if (isAuth) {
        fetchInitialData().then(() => {
          socket.connect()
          setIsLoading(false);
        });
      } else {
        // Different behavior for browser and native app
        if (isWebView === true) {
          // Send logout message to native app
          const nativeMessage = {accountEvent: 'logout'};
          console.log("Sending logout message to native app", nativeMessage);
          window.ReactNativeWebView.postMessage(JSON.stringify(nativeMessage));
        } else {
          // If user not logged-in or jwt token expires, direct to login page.
          navigate(`/login`); 
        }

        setIsLoading(false);
      }
    })
  });

  // Obtain user data
  const fetchInitialData = async () => {
    try {
      // Obtain user profile details
      await userService.getOwnUserInfoDetails()
      .then((res) => {
        dispatch(updateUser(res.data));
      });

      // Obtain user app Settings
      await settingService.getUserSetting()
      .then((res) => {
        dispatch(updateSettings(res.data));
      });

      // Obtain Chat User Association
      await chatService.getChatUserAssociation()
      .then((res) => {
        dispatch(updateChatUserAssociation(res.data));
      });

      // Obtain user's Default Category
      await chatService.getDefaultCategory()
      .then((res) => {
        dispatch(updateDefaultCategory(res.data));
      });
      
      // Obtain list of Chat Categories
      await chatService.getCategories()
      .then((res) => {
        dispatch(updateCategories(res.data));
      });

      // Obtain Chat messages
      await chatService.getCategoriesChatsMessages()
      .then((res) => {
        dispatch(updateChats(res.data.chats));
        dispatch(updateLastMessages(res.data.lastMessages));
      });
      
      // Obtain list of Group Chats
      await groupChatService.getGroupChats()
      .then((res) => {
        dispatch(updateGroupChats(res.data));
      })

      // Obtain list of Group Chat & User associations
      await groupChatService.getGroupChatUserAssociations()
      .then((res) => {
        dispatch(updateGroupChatUserAssociations(res.data))
      });

      // Obtain Events claimed by user
      await eventService.getUserClaimedEvents()
      .then((res) => {
        dispatch(updateEvents(res.data));
      });

    } catch (err) {
      console.log(err);
    }
  };

  // Socket connection handle
  useEffect(() => {
    const socketConnected = () => {
      console.log('Connected to io server');
    }
    const onReceiveMessage = chatMessage => {
      //dispatch(updateLastMessage(chatMessage)); // DO NOT SET REDUX STATE IN A SOCKET CALL!
      //sendPushNotification("M8: You have new message", "Someone send you a message, go check it out!", "");
    }
    const onNewChat = newChat => {
      //dispatch(addNewChat(newChat)); // DO NOT SET REDUX STATE IN A SOCKET CALL!
    }

    socket.on('connect', socketConnected);

    socket.on('newChat', onNewChat);

    socket.on("receiveMessage", onReceiveMessage);

    return () => {
      socket.off('connect', socketConnected)
      socket.off('newChat', onNewChat);
      socket.off('receiveMessage', onReceiveMessage);
    };
  }, [socket]);

  /* Loading indicator*/
  if (isLoading) {
    return (
      <Box
        sx={{
          position: 'fixed', // Fixed position
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center', // Vertical centering
          justifyContent: 'center', // Horizontal centering
        }}
      >
      <CircularProgress color="inherit" />
    </Box>
    )
  }

  /* Back to login if not logged in */
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  /* To main app if logged in */
  return (
    <Box className="app-container" >
      {/* Only show header/navbar for browser, not so for native app */}
      {isWebView === false ? 
        <NavBar />
      :
        <> </>
      }
      <Outlet />
      <WindowFocusHandler/>
    </Box>
  );
};

