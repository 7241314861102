import axios from "../axios"

const register = async (body) => {
  try {
    ///*
    const response = await axios.post(`/user/register`, body);
    return response.data;
    //*/
    //console.log(body)
    //return true;
  } catch (error) {
    // Handle or throw error
    throw error;
  }
};

const login = async (body) => {
  console.log('userService - login called with:', body);
  try {
      const response = await axios.post(`/user/login`, body);
      console.log('userService - received response:', response);
      return response;
  } catch (error) {
      console.log('userService - caught error:', error);
      return error.response;
  }
};

const updateEmailNotifications = async (emailNotifications) => {
  try {
      const response = await axios.post(`/user/updateEmailNotifications`, { emailNotifications });
      return response.data;
  } catch (error) {
      throw error;
  }
};


const getCurrentUser = async () => {
  try {
    const response = await axios.get(`/user/currentUser`);
    return response.data;
  } catch (error) {
    // Handle or throw error
    throw error;
  }}
  
const logout = async () => {
  try {
    const response = await axios.post(`/user/logout`);
    return response.data;
  } catch (error) {
    // Handle or throw error
    throw error;
  }
};

const forgotPassword = async (body) => {
  
  try {
      const response = await axios.post(`/user/forgot-password`, body );
      console.log('Response:', response);

      if (response.status === 200) {
          return { message: 'Password reset link has been sent to your email. Please check your inbox (and spam folder).' };
      } else {
          return { message: 'There was an issue sending the password reset link. Please try again later.' };
      }
  } catch (error) {
      console.error('Error sending password reset link:', error.response?.data || error.message);
      return { message: 'Failed to send password reset link. Please try again later.' };
      //throw error;
  }

};

const resetPassword = async (body) => {
  try {
      await axios.post('/user/reset-password', body);
      //alert();
      return { message: 'Password reset successfully.' };
      //navigate('/login');
  } catch (error) {
      //alert('Failed to reset password. Please try again.');
      return { message: 'Failed to send password reset link. Please try again later.' };
  }
};

const checkToken = async () => {
  try {
    const response = await axios.get(`/user/checkToken`);
    return response;
  } catch (error) {
    // Handle or throw error
    throw error;
  }
};

const verifyToken = async (token) => {
    try {
        const response = await axios.post(`/user/verify-email`, {
            token
        });
        return response;
    }
    catch (error) {
        throw error;
    }
};

const getUserDetails = async (body) => {
  try {
    const response = await axios.post(`/user/getUserInfo`, body);
    return response.data;
  } catch (error) {
    // Handle or throw error
    throw error;
  }
};

const getOwnUserInfoDetails = async () => {
  try {
    const response = await axios.get(`/user/ownUserInfo`);
    return response;
  } catch (error) {
    // Handle or throw error
    throw error;
  }
};

const getAllUserInfoDetails = async () => {
  try {
    const response = await axios.get(`/user/allUserInfo`);
    return response;
  } catch (error) {
    // Handle or throw error
    throw error;
  }
};

const getUserInfosByCategoryId = async (body) => {
  try {
    const response = await axios.post(`/user/getUserInfosByCategoryId`, body);
    return response.data;
  } catch (error) {
    // Handle or throw error
    throw error;
  }
}

const modifyUserInfo = async (body) => {
  try {
    const response = await axios.put(`/user/modifyUserInfo`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const searchUserInfos = async (body) => {
  try {
    const response = await axios.post(`/user/searchUserInfos`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getUsercount = async () => {
    try {
        const response = await axios.get(`/user/countUser`);
        return response.data;

    } catch (error) {
        throw error;
    }
};

const getReferredUserCount = async (body) => {
  try {
    const response = await axios.post(`/user/countReferredUsers`, body);
    return response;
  } catch (error) {
    throw error;
  }
}

const userService = {
  register,
  login,
  logout,
  checkToken,
  verifyToken,
  forgotPassword,
  getCurrentUser,
  resetPassword,
  updateEmailNotifications,
  getUserDetails,
  getOwnUserInfoDetails,
  getAllUserInfoDetails,
  getUserInfosByCategoryId,
  modifyUserInfo,

  searchUserInfos,

  getUsercount,
  getReferredUserCount
};

export default userService;




