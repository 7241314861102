import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import EventList from '../components/events/EventList';
import NewMemberList from '../components/MemberList/NewMemberList';
import MemberCard from '../components/memberCard/MemberCard';

import { Box, Container, Typography } from '@mui/material';

import userService from '../api/services/userService';
import eventService from '../api/services/eventService';

import PopupSlideshow from '../components/popup/Slideshow/PopupSlideshow'; 
import HomePageTutorial from '../components/tutorial/2HomePageTutorial/HomePageTutorial';
import HelpButton from 'components/tutorial/HelpButton';

export default function HomePage() {
  const isMobile = useSelector(state => state.global.isMobile);
  const isWebView = useSelector(state => state.global.isWebView);
  const navigate = useNavigate();

  const [newMembers, setNewMembers] = useState([])
  const [events, setEvents] = useState([]);

  // Styles 
  const titleStyle = {
    fontWeight: 'bold', fontSize: 'larger'
  }

  // Data (user info and events) fetching on startup 
  useEffect(() => {
    userService.getAllUserInfoDetails().then((res) => {
      const last100Members = res.data.slice(-100);
      setNewMembers(last100Members.reverse());
    })

    eventService.getAllEvents().then((res) => {
      setEvents(res.slice(0,5));
    });
  }, [])

  // First-time User Experience: Show tutorial popup for first-time users 
  const [tutorialVisible, setTutorialVisible] = useState(false);
  const tutorialPopup = (
      <PopupSlideshow 
        content={ <HomePageTutorial tutorialVisible={tutorialVisible} setTutorialVisible={setTutorialVisible}/> } 
        visible={tutorialVisible} 
        setVisible={setTutorialVisible}
        backdrop={true}
        association={"first-time-home"}
      />
  );
  // Floating help button logic 
  const handleHelpButtonClick = () => {
    localStorage.setItem("first-time-home", true);
    setTutorialVisible(true);
  }
  useEffect(() => {
    // If tutorial flag, show tutorial
    localStorage.getItem("first-time-home") && setTutorialVisible(true);

    // If new user flag, direct to profile to begin tutorial
    localStorage.getItem("first-time") && navigate(`/profile`);
  },[])

  /* Section 1 contents */
  const SectionContent1 = (<>

    {/* New Members section */}
    <Typography variant="subtitle1" component="div" sx={titleStyle} gutterBottom>
      New Members
    </Typography>
    <div >
      {/* <HorizontalScroll title={"Top Search"} Component={MemberCard} data={newMembers} /> */}
      {newMembers && newMembers.length > 0 && (
        <NewMemberList  Component={MemberCard} data={newMembers} />
      )}
    </div>
    
  </>)

  /* Section 2 contents */
  const SectionContent2 = (<>

    {/* Events & D8s */}
    <Container 
      className='home-events'
      sx={ isMobile ?
        { // Mobile
          width: 'inherit',
          height: '450px',
        }
        :
        { // PC
          height: '450px',
          minHeight: '300px',
          width: '450px',
          '@media (max-width: 450px)': {
            width: '90vw'
          },
        }
      }
    >
      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: 'inherit'}}>
        <Typography variant="subtitle1" component="div" sx={titleStyle} gutterBottom>
          Events  
        </Typography>
        
        <Typography component="div" variant="subtitle1" sx={{textAlign: 'right', color: 'var(--color-text)', }} gutterBottom>
          <Link to="/event"><span style={{color: 'var(--color-text)'}}>See all</span></Link>&nbsp;&nbsp;
        </Typography>
      </Box>
      <Container
      sx={
        isMobile ?
        { // Mobile
          height: 'inherit',

          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',

          alignContent: 'flex-start',

          overflowX: 'auto',
          whiteSpace: 'nowrap',

          border: '2px solid var(--color-accent)',
          borderRadius: 'var(--border-radius-main)',

          mt: 1,
          mb: 1,
        }
        :
        { // PC
          height: 'inherit',

          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',

          overflowX: 'auto',
          whiteSpace: 'nowrap',

          border: '2px solid var(--color-accent)',
          borderRadius: 'var(--border-radius-main)',

          gap: 2,
        }
      }
      >
        {events && events.length > 0 && (
          <EventList events={events} setEvents={setEvents} marginTop="30px" displayMode="List"/>
        )}
      </Container>
    </Container>


    {/* Unknown section to be filled */}
    {/*
    <Container 
      className='home-a'
      sx={
        isMobile ?
        { // Mobile
          width: 'inherit',
          height: '450px',
        }
        :
        { // PC
          height: '450px',
          width: '30vw',
        }

      }
    >
      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: 'inherit'}}>
        <Typography variant="subtitle1" component="div" sx={titleStyle} gutterBottom>
          
        </Typography>
        
        <Typography component="div" variant="subtitle1" sx={{textAlign: 'right', color: 'var(--color-text)', }} gutterBottom>
          <Link to="/"><span style={{color: 'var(--color-text)'}}>See all</span></Link>&nbsp;&nbsp;
        </Typography>
      </Box>
      <Container
      sx={
        isMobile ?
        { // Mobile
          height: 'inherit',

          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',

          alignContent: 'flex-start',

          overflowX: 'auto',
          whiteSpace: 'nowrap',

          //border: '2px solid var(--color-accent)',
          borderRadius: 'var(--border-radius-main)',

        }
        :
        { // PC
          height: 'inherit',

          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',

          overflowX: 'auto',
          whiteSpace: 'nowrap',

          //border: '2px solid var(--color-accent)',
          borderRadius: 'var(--border-radius-main)',

          gap: 2,
        }
      }
      >
        
      </Container>
    </Container>
    */}
    
  </>)

  return (
    <Box
      className="home-container"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh',
        overflowY: 'scroll'
      }}
    >
      <Box mt={!isWebView ? "90px" : "30px"}></Box>

      {/* Section 1 */}
      <Container 
        className='home-container-1'
        sx={
          isMobile ?
          { // Mobile
            mb: 5
          }
          :
          { // PC
            width: '1900px',
            '@media (max-width: 1280px)': {
              width: '90vw'
            },
            mb: 5
          }
        }
      >
        {SectionContent1}
      </Container>

      {/* Section 2 */}
      <Container 
        className='home-container-2'
        sx={
          isMobile ?
          { // Mobile
            mb: 15,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: 12
          }
          :
          { // PC
            mb: 15,
            width: '80vw',
            height: '400px',
            display: 'flex',
            justifyContent: 'center',

          }
        }
      >
        {SectionContent2}
      </Container>

      {/* Help buttons */}
      {/*<HelpButton onClick={handleHelpButtonClick}/>*/}
      {/* First time user tutorial */}
      {tutorialPopup}

    </Box>
  );
}
