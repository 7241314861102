// React, Redux, DOM essentials
import React, { useEffect, useState, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// MUI components
import { 
    Avatar, 
    Box, 
    CircularProgress, 
    Typography, 
    IconButton, 
    Menu, 
    MenuItem, 
    Dialog, 
    DialogTitle, 
    DialogActions, 
    List, 
    ListItem, 
    ListItemText, 
    ListItemButton, 
    ListItemAvatar, 
    Button 
} from '@mui/material';

// MUI icons
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PersonIcon from '@mui/icons-material/Person';

// MUI style control
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Services
import chatService from 'api/services/chatService';
import socket from 'socketio/socket';

// State management (Redux)
import { 
  deleteChat, 
  updateChatCategory,
  setProfileDrawerVisible,
} from 'app/slices/chat.slice';

// Custom scriots
import { delay } from 'scripts/delay';

export default function MoveChatDialog({handleClick, handleClose, handlePopupOpen, handlePopupClose, popupOpen}) {
    const isMobile = useSelector(state => state.global.isMobile);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // User & Chat data from Redux store
    const chatId = useSelector(state => state.chats.chatSessionId); 
    const chatUserAssociationId = useSelector(state => state.chats.chatUserAssociation[chatId] && state.chats.chatUserAssociation[chatId]._id);
    const chatUserAssociationCategoryId = useSelector(state => state.chats.chatUserAssociation[chatId] && state.chats.chatUserAssociation[chatId].categoryId);
    const categories = useSelector(state => state.chats.categories);

    // Friend profile dawer (mobile only)
    const handleProfileDrawer = () => {
        handleClose();
        setProfileDrawerVisible(true);
    }
    
     // Change Chat category handle
    const handleMoveChat = (categoryId) => {
        handlePopupClose();
        chatService.changeChatCategory({ chatUserAssociationId, newCategoryId: categoryId, chatId }).then(res => {
        const newChatUserAssociation = res.data;
        const oldCategoryId = chatUserAssociationCategoryId;
        dispatch(updateChatCategory({ newChatUserAssociation, oldCategoryId }));

        //socket.emit('refreshChatSignal',{})

        }).catch(err => {
            console.log(err);
        });
    };

    /* Chat Category mover popup */
    const MoveChatDialog = (
        <Dialog 
            fullWidth 
            maxWidth="sm"
            PaperProps={{
                sx: {
                maxHeight: '50vh'
                }
            }}
            open={popupOpen} 
            onClose={handlePopupClose} 
        >
            <DialogTitle sx={{ fontSize: "1.1rem", fontWeight: 'bold'}}>Move chat to...</DialogTitle>
            <List sx={{ pt: 0, width: "100%", overflowY: 'scroll' }}>
                {Object.values(categories).map((category) => (
                    <ListItem disableGutters key={category._id}>
                    <ListItemButton onClick={() => handleMoveChat(category._id)} sx={{ padding: "0 24px", borderRadius: '5px' }}>
                        <ListItemAvatar>
                        <Avatar /*variant='rounded'*/ sx={{ width: 30, height: 30 }}> 
                            <PersonIcon />
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={category.name} />
                    </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Box mb={4}></Box>
            <DialogActions>
                <Button 
                    size="small" 
                    onClick={handlePopupClose} 
                    sx={
                    { 
                        backgroundColor: '#333', 
                        '&:hover': {
                            backgroundColor: '#555', 
                        },
                        borderRadius: '5px',
                        color: '#fff', 
                        width: 90,

                        position: 'absolute',
                        bottom: 15,
                        right: 15
                    }
                    }
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )

    return <>
        {/* Dialog */}    
        {MoveChatDialog}
    </>

}