import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { claimEvent, unclaimEvent } from 'app/slices/events.slice';
import eventService from 'api/services/eventService';

import { Card, CardMedia, CardContent, CardActions, Typography, Box, Button, Avatar, Stack, styled } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import GroupsIcon from '@mui/icons-material/Groups';
import { filledButtonStyle } from 'styles/buttonStyles';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { isMobile } from 'scripts/mobileCheck';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const ContentContainer = styled(`div`)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '25px'
});

const StyledAvatar = styled(Avatar)({
  width: '120px',
  height: '120px',
  marginBottom: '1em',
  borderRadius: '50%', // Circular image
  border: '4px solid white',
  boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.2)'
});

const EventInfo = styled(Box)({
  textAlign: 'center',
  marginBottom: '2em'
});

const formatDate = (dateString) => {
  // Parsing the ISO date string and converting it to local time
  return dayjs(dateString).utc().local().format("MMMM D, YYYY h:mm A");
};

export default function EventDrawerContent({ isPreview, event }) {
  const isMobile = useSelector(state => state.global.isMobile);

  const dispatch = useDispatch();
  const eventId = event._id
  const claimedEvents = useSelector(state => state.events.claimedEvents)

  const [claimed, setClaimed] = useState(false)

  useEffect(() => {
    if (claimedEvents[eventId]) setClaimed(true)
  }, [])

  const handleClaimEvent = () => {
    eventService.claimEvent(eventId).then(res => {
      dispatch(claimEvent(res))
      setClaimed(true)
    })
  }

  const handleUnclaimEvent = () => {
    eventService.unclaimEvent(eventId).then(res => {
      dispatch(unclaimEvent(eventId))
      setClaimed(false)
    })
  }

  const renderCost = () => (
    event.discountedCost ? (
      <Stack direction="row" spacing={1} ml={1} alignItems="center">
        <Typography variant="body2" component="span" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
          ${event.cost.toFixed(2)}
        </Typography>
        <Typography variant="body2" component="span" color="green">
          ${event.discountedCost.toFixed(2)}
        </Typography>
      </Stack>
    ) : (
      <Typography variant="body2" component="span" color="primary" ml={1}>
        ${event.cost.toFixed(2)}
      </Typography>
    )
  );

  const desktopLayout = (
    <Card 
      sx={
        { 
          width: "100%", 
          height: "100%",
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignContent: 'center'
        }
      }
    >
      {event.image && (
        <CardMedia
          sx={{ 
            width: '500px',
            height: '500px',
            borderRadius: 'var(--border-radius-main)',
            mt: 5
          }}
          image={event.image}
          title={event.name}
        />
      )}
      <Box 
        sx={{
          //border: '2px solid var(--color-accent)',
          //borderRadius: 'var(--border-radius-main)',
          ml: 5,
          mt: 6
        }}
      >
        <CardContent sx={!event.image && { mt: 4 }}>
          <Typography variant="body2" sx={{ mb: 1, fontStyle: 'italic' }}>
            {formatDate(event.eventDateTime)}
          </Typography>
          <Typography variant="h6" component="div">
            {event.name}
          </Typography>
          <Typography variant="body2" sx={{ mt: 2, mb: 2, width: 600 }}>
            {event.description}
          </Typography>

          <Box display="flex" alignItems="center">
            <LocationOnIcon fontSize='small' />
            <Typography variant="body2" sx={{ ml: 1 }}>
              {event.location}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1}>
            <LocalOfferIcon fontSize='small' />
            {renderCost()}
          </Box> 
          <Box display="flex" alignItems="center" mt={1}>
            <GroupsIcon fontSize='small' />
            <Typography variant="body2" ml={1} >
            {event.numberAvailable} spots remaining
            </Typography>
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: 'left', mt: 5, ml: 1 }}>
          <Button sx={filledButtonStyle} disabled={isPreview} onClick={claimed ? handleUnclaimEvent : handleClaimEvent}>{ claimed ? "Unclaim" : "Claim" }</Button>
        </CardActions>
      </Box>
    </Card>
  )

  const mobileLayout = (
    <Card sx={{ width: "100%" }}>
      {event.image && (
        <CardMedia
          sx={{ height: 225 }}
          image={event.image}
          title={event.name}
        />
      )}
      <CardContent sx={!event.image && { mt: 4 }}>
        <Typography variant="body2" sx={{ mb: 1, fontStyle: 'italic' }}>
          {formatDate(event.eventDateTime)}
        </Typography>
        <Typography variant="h6" component="div">{event.name}</Typography>
        <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>{event.description}</Typography>

        <Box display="flex" alignItems="center">
          <LocationOnIcon fontSize='small' />
          <Typography variant="body2" sx={{ ml: 1 }}>
            {event.location}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mt={1}>
          <LocalOfferIcon fontSize='small' />
          {renderCost()}
        </Box> 
        <Box display="flex" alignItems="center" mt={1}>
          <GroupsIcon fontSize='small' />
          <Typography variant="body2" ml={1} >
          {event.numberAvailable} spots remaining
          </Typography>
        </Box>
      </CardContent>
      <CardActions sx={{ justifyContent: 'center' }}>
        <Button sx={filledButtonStyle} disabled={isPreview} onClick={claimed ? handleUnclaimEvent : handleClaimEvent}>{ claimed ? "Unclaim" : "Claim" }</Button>
      </CardActions>
    </Card>
  )


  return ( isMobile ?
    <> 
      {mobileLayout}  
    </>
    :
    <>
      {desktopLayout}  
    </>
  );
}
