// React essentials
import React, { useEffect, useState } from 'react';

// MUI components
import Box from '@mui/material/Box';

export const BlobSeparator = ({ mt, mb, height, color }) => {
  return (
    <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: mt ? mt : 4,
          mb: mb ? mb : 4,
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            height: height ? height : 3,
            width: 20,
            backgroundColor: color ? color : 'var(--color-accent)',
            border: `2px solid ${color ? color : 'var(--color-accent)'}`,
            borderRadius: 9999,
          }}
        ></Box>   
      </Box>
  )
}