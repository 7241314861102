
// Summary: This file contains the main search page component. It contains the search form 
// and search results components. The search form allows users to input their search criteria and 
// the search results component displays the search results based on the search criteria. The search results
//  component is only displayed when the user has submitted the search form. The search form and search results 
// components are displayed differently based on the user's device. On mobile, the search form is displayed first
//  and then the search results are displayed in full, replacing the search form. On PC, both the search form and 
// search results are displayed at the same time as there is enough space. The search page also contains a tutorial 
// popup that is displayed to first-time users. The tutorial popup contains a tutorial for the search page. 
// The tutorial popup is displayed when the user first visits the search page and is not displayed again after 
// the user has seen it once. The search page also contains a floating help button that allows users to access the 
// tutorial popup at any time.

import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import PopupSlideshow from '../components/popup/Slideshow/PopupSlideshow';
import SearchPageTutorial from '../components/tutorial/Search/SearchPageTutorial';

import SearchResults from '../components/search/SearchResults';
import Search from '../components/search/Search';

import { isMobile } from '../scripts/mobileCheck';
import HelpButton from 'components/tutorial/HelpButton';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

export default function SearchPage() {
  const initialCategoryValues = {
    basics: {
      currentCity: [],
      genderIdentity: '',
      stageOfLife: [],
      religion: '',
      ageRange: [18, 100],
    },
    career: {
      schools: [],
      occupation: [],
      classes: [],
      company: '',
      income: '',
    },
    physical: {
      heightRange: [24, 108],
      weightRange: [50, 600],
      ethnicity: [],
      eyeColor: [],
      hairColor: [],
      bodyType: '',
      disability: [],
    },
    personality: {
      personalityType: [],
      introExtroVert: [],
      morningNight: [],
      favoriteEmojis: [],
      relationshipIntent: '',
      friendIntent: '',
      idealFD8: [],
      idealRD8: [],
      coreValues: [],
    },
    theTea: {
      interests: [],
      drugsAlcohol: [],
      music: [],
      tvMovies: [],
      comedians: [],
      sportsTeams: [],
      citiesVisitedLived: [],
      politics: [],
      sexuality: [],
      roommatePreference: [],
    },
  };

  const [results, setResults] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState({
    prefer: { ...initialCategoryValues },
    require: { ...initialCategoryValues },
    exclude: { ...initialCategoryValues },
  });

  // First-time User Experience: Show tutorial popup for first-time users
  const [tutorialVisible, setTutorialVisible] = useState(false);
  const tutorialPopup = (
    <PopupSlideshow
      content={
        <SearchPageTutorial
          tutorialVisible={tutorialVisible}
          setTutorialVisible={setTutorialVisible}
        />
      }
      visible={tutorialVisible}
      setVisible={setTutorialVisible}
      backdrop={true}
      association={'first-time-search'}
    />
  );
  // Floating help button logic
  const handleHelpButtonClick = () => {
    localStorage.setItem('first-time-search', true);
    setTutorialVisible(true);
  };
  useEffect(() => {
    localStorage.getItem('first-time-search') && setTutorialVisible(true);
  }, []);

  return (
    <ThemeProvider theme={lightTheme}>
      {/* First time user tutorial */}
      {tutorialPopup}

      {/* Main container */}
      <Box
        className="search-page-container"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '82vh',
          mt: 4,
          gap: 2,
        }}
      >
        <>
          {isMobile() ? (
            // On mobile, only show search form, then show result in full replacing the search form
            <>
              {/* {results ? (
                <SearchResults results={results ? results : []} setResults={setResults} />
              ) : ( */}
                <Search
                  searchCriteria={searchCriteria}
                  setSearchCriteria={setSearchCriteria}
                  setResults={setResults}
                  results={results}
                />
              {/* // )} */}
            </>
          ) : (
            // On PC, show both search results and search form all the time as there's enough space
            <>
              <Search
                searchCriteria={searchCriteria}
                setSearchCriteria={setSearchCriteria}
                setResults={setResults}
              />
              <SearchResults results={results ? results : []} setResults={setResults} />
            </>
          )}
        </>
      </Box>

      {/*<HelpButton onClick={handleHelpButtonClick}/>*/}
    </ThemeProvider>
  );
}