// React, Redux essentials
import React, { useEffect, useReducer, useState } from 'react';

// State management (Redux)
import { useSelector, useDispatch } from "react-redux";
import { 
    deleteChat, 
    updateChatCategory,
    setChatSessionId, 
    setFriendId, 
    setFriendUserInfo,
    setSelectedCategoryId,
    setProfileDrawerVisible
} from 'app/slices/chat.slice';

// Socket.IO
import socket from 'socketio/socket';

// Custom hooks
import { useMouseHover } from 'hooks/onMouseHover';
import WindowFocusHandler from 'hooks/WindowFocusHandler';

// API service calls
import chatService from 'api/services/chatService';
import userService from 'api/services/userService';

// MUI components
import { Box, Typography, Avatar, ButtonBase } from '@mui/material';

// MUI icons
import PersonIcon from '@mui/icons-material/Person';

// Components
import ChatMessageOptions from './ChatMessageOption';

// Custom functions
import { delay } from 'scripts/delay';
import { mongoDateConver } from 'scripts/mongoDateConvert';



// Perspective consideration: 'you' are the current login user, 'friend' is the user you're chatting with.

function ChatMessage({ chatUserInfo, message, userId, isSameUserAsPrevious, isSameUserAsNext }) {
  const isMobile = useSelector(state => state.global.isMobile);
  const dispatch = useDispatch();
  const [hovered, eventHandlers] = useMouseHover();
  const onFocus = useSelector(state => state.global.appOnFocus);

  const isUserSender = message.senderId === userId;

  const currentChatId = useSelector(state => state.chats.chatSessionId);

  const senderName = chatUserInfo[message.senderId] && chatUserInfo[message.senderId].name;
  const senderAvatar = chatUserInfo[message.senderId] && Object.hasOwn(chatUserInfo[message.senderId], 'images') && chatUserInfo[message.senderId].images.length > 0 ? chatUserInfo[message.senderId].images[0].mediaUrl : 'unknown';

  const [readStatus, setReadState] = useState(message.isRead ? 'Read' : 'Unread');
  const [messageVisible, setMessageVisible] = useState(true);

  /* New message indicator (Black dot next to chat bubble) */
  const [NewMessageIndicator, setNewMessageIndicator] = useState(<></>);

  // Initial render functions
  useEffect(() => {

    // Only add indicator to message(s) send by friend and when user is unfocused 
    if (message.receiverId === userId  && readStatus === 'Unread') {
      setNewMessageIndicator(<> 
        <Typography variant="body2" fontSize={'18px'} sx={{wordBreak: 'break-all', ml: 1}}>•</Typography>
      </>)
    }

    // Remove new message indicator and set message immidiately as read when user is on current chat
    if (message.receiverId === userId && message.chatId === currentChatId && onFocus === true && readStatus === 'Unread') {
      socket.emit('readMessage', { receiverId: userId, senderId: message.senderId, chatId: message.chatId});
    }

    // Hide friend's chat message if hidden by user
    message.hiddenByReceiver && message.receiverId === userId ? setMessageVisible(false) : void(0);

    // Hide user's own chat message if hidden by user
    message.hiddenBySender && message.senderId === userId ? setMessageVisible(false) : void(0);

  },[])
  
  // Remove new message indicator on friend's messages when you sent yours.
  useEffect(() => {
    
    const markFriendMessageRead = (newMessage) => {
      if (newMessage.senderId === userId && currentChatId === newMessage.chatId ) {
        setNewMessageIndicator(<></>)
      }
    }
    socket.on('receiveMessage', markFriendMessageRead)
    socket.on('')

    return () => {
      socket.off('receiveMessage', markFriendMessageRead);
    };
 
  },[socket])

  // If friend see your message, set your message status as Read
  useEffect(() => {
    const setMessageToRead = () => {
      setReadState('Read');
      
    }
    socket.on('messageReadByReceiver', setMessageToRead);
  
    return () => {
      socket.off('messageReadByReceiver', setMessageToRead);
    };
  },[socket])


  // Set friend messages to Read when you focus on app's tab after being unfocused (like checking different tabs or different window)
  useEffect(() => {
    if (message.isRead === false && message.receiverId === userId && onFocus === true && currentChatId === message.chatId) {
      socket.emit('readMessage', { receiverId: userId, senderId: message.senderId, chatId: message.chatId })
      
    } 
  },[onFocus])

  // Hide or delete chat when user take said action
  const [deleteMessageIds, setDeletedIds] = useState([]);
  useEffect(() => {
    const messageDeleted = (payload) => {
      //setDeletedIds([...deleteMessageIds, ]);
      if (payload.deletedMessageId === message._id) {
        setMessageVisible(false);
      }
      //setMessageVisible(false);
    }
    socket.on('chatMessageDeleted', messageDeleted);
  
    return () => {
      socket.off('chatMessageDeleted', messageDeleted);
    };
  },[socket])

  // Show dropdown icon when mouse hover over message body
  const [dropdownOpacity, setDropdownOpacity] = useState(0);
  useEffect(() => {
    if (hovered === true) {
        setDropdownOpacity(0.5);
    } else {
      setDropdownOpacity(0);
    }
},[hovered])

  //if (deleteMessageIds === message._id) {
  //  return <></>
  //}
  return (
    messageVisible ?
    <Box
      className="chatMessage"
      display="flex"
      flexDirection="column"
      alignItems={isUserSender ? 'flex-end' : 'flex-start'}
      sx={{ mt: isSameUserAsPrevious ? 0.5 : 2, }}
    >
      {!isUserSender && !isSameUserAsPrevious && (<></>
        /* Message sender */
        //<Typography fontWeight="body1" variant="body2" sx={{ mb: 0.5, ml: 6.25 }} > {senderName} </Typography>
      )}
      <Box display="flex" justifyContent={isUserSender ? 'flex-end' : 'flex-start'}>

        {/* Place message option button to left for user message */}
        {message.senderId === userId && <Box sx={{opacity: dropdownOpacity, transition: '0.1s', "&:hover": {opacity: 0.5} }}><ChatMessageOptions message={message} userId={userId} isMobile={isMobile}/></Box>} 

        {!isUserSender && (
          <Avatar 
            alt={senderName} 
            src={senderAvatar} 
            sx={{ 
              visibility: isSameUserAsPrevious ? 'visible' : isSameUserAsNext ? 'hidden' : 'visible',
              width: 35, 
              height: 35, 
              mr: 1, 
              mt: 1
            }}
            onClick={() => {dispatch(setProfileDrawerVisible(true));}}
          >
            <PersonIcon />
          </Avatar>
        )}
        <Box
          {...eventHandlers} 
          sx={{ 
            borderRadius: isUserSender ? '16px 16px 0px 16px' :  '0px 16px 16px 16px', 
            maxWidth: "280px", 
            textAlign: isUserSender ? 'right' : 'left',
            "&:hover": {
              cursor: 'pointer'
            } 
          }}
          bgcolor={isUserSender ? 'var(--color-chat-bubble-sender)' : 'var(--color-chat-bubble-receiver)'}
          color={isUserSender ? 'var(--color-text-invert)' : 'var(--color-text)'}
          padding={1.25}
          maxWidth="60%"
          //onClick={async () => {setDropdownOpacity(0.5); await delay(2000); setDropdownOpacity(0);}}
        >
          {/* The message content */}
          <Typography variant="body2" sx={{wordBreak: 'break-word' }}>{message.content}</Typography>

          {/* Message send/receive date and (For your message only) Read/Unread status */}
          <Typography variant="body2" fontSize={'12px'} sx={{wordBreak: 'break-all', opacity: 0.4, textAlign: 'right'}}>
            {mongoDateConver(message.createdAt)} {isUserSender && <>&nbsp;•&nbsp; {readStatus}</> }
          </Typography>
        </Box>

        {NewMessageIndicator}

        {/* Place message option button to right for friend message */}
        {message.receiverId === userId && <Box sx={{opacity: dropdownOpacity, transition: '0.1s', "&:hover": {opacity: 0.5} }}><ChatMessageOptions message={message} userId={userId} isMobile={isMobile}/></Box>}
      </Box>
      
    </Box>
    :
    <></>
  );
}

export default ChatMessage;
